import { useEffect, useRef, useState } from "react";

import { ActionCreatorWithPayload, AsyncThunkAction } from "@reduxjs/toolkit";
import {
  DropdownOnSearchChangeData,
  DropdownProps,
  Form,
} from "semantic-ui-react";

import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";

import { IProgramedExcluding, ISettings } from "../../../slices/settings.slice";
import {
  getSearchCustomers,
  ICustomer,
  selectCustomers,
  getManyCustomers,
} from "../../../slices/customers.slice";
import {
  getSearchContacts,
  IUser,
  selectContacts,
  getManyUsers,
} from "../../../slices/users.slice";
import { ITeam } from "../../../slices/teams.slice";

import CustomCollapsible from "../CustomColapsible.component";
import Dropdown from "../Dropdown.component";
import DefaultModal from "../Modals/Default.modal";
import { AppDispatch } from "../../../app/store";

interface ComponentExcludingProps {
  selectedInstance: ITeam | ISettings | null;
  getInstance?: (x: string) => Promise<ddOptions[]>;
  handleUpdate: (
    _event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
  type: "chats" | "attachments";
}

interface ProgramedExcludingProps {
  selectedInstance: ITeam | ISettings | null;
  instanceDispatch: ActionCreatorWithPayload<any | null, any>;
}

const OPTIONS = {
  skip: 0,
  limit: 10,
  filter: "",
  deleted: false,
};

const PERIOD = [
  { key: 1, text: "15 dias", value: 15 },
  { key: 2, text: "30 dias", value: 30 },
  { key: 3, text: "45 dias", value: 45 },
  { key: 4, text: "60 dias", value: 60 },
];

const normalizeToOptions = (array: Array<any>) =>
  array.map((item: any) => ({
    key: item._id,
    text: item.name,
    value: item._id,
  }));

const setValue = (obj: Record<string, any>, path: string, value: any) => {
  const keys = path.split(".");

  keys.reduce((acc: Record<string, any>, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      acc[key] = acc[key] || {};
    }
    return acc[key];
  }, obj);

  return obj;
};

const fetchData = async (
  type: "customers" | "contacts",
  query: string,
  dispatch: AppDispatch
): Promise<any[]> => {
  const actionMap = {
    customers: getSearchCustomers,
    contacts: getSearchContacts,
  };

  const options = { ...OPTIONS, filter: query };
  const result = await dispatch(actionMap[type](options));
  return result.payload.results;
};

const updateGlobalState = (
  type: "customers" | "contacts",
  newData: ICustomer[] | IUser[],
  dispatch: AppDispatch
) => {
  const actionMap = {
    customers: selectCustomers,
    contacts: selectContacts,
  };

  dispatch(actionMap[type](newData));
};

export const ProgramedExcluding = ({
  selectedInstance,
  instanceDispatch,
}: ProgramedExcludingProps) => {
  const [openBanner, setOpenBanner] = useState(false);
  const bannerShown = useRef(false);

  const dispatch = useAppDispatch();

  const getInstance = async (instance: string) => {
    const actionMap: Record<string, () => AsyncThunkAction<any, any, any>> = {
      customers: () => getSearchCustomers(OPTIONS),
      contacts: () => getSearchContacts(OPTIONS),
    };

    const result = await dispatch(actionMap[instance]());

    return normalizeToOptions(result.payload.results);
  };

  const handleUpdate = (
    _event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    if (selectedInstance) {
      const clone = structuredClone(selectedInstance);
      dispatch(instanceDispatch(setValue(clone, data.id, data.value)));
    }
  };

  const handleDisable = (type: "chats" | "attachments" | "full") => {
    if (!selectedInstance) return;

    const clone = structuredClone(selectedInstance);

    if (type === "full") {
      clone.programed_excluding = null;
      bannerShown.current = false;
    } else {
      delete clone.programed_excluding?.[type];
    }

    dispatch(instanceDispatch(clone));
  };

  function handleOpenBanner() {
    setOpenBanner(true);
    bannerShown.current = true;
  }

  function handleCloseBanner() {
    setOpenBanner(false);
  }

  const initialState = async () => {
    await getInstance("contacts");
    await getInstance("customers");
  };

  const openAction = () => {
    if (!!selectedInstance?.programed_excluding) {
      return () => handleDisable("full");
    } else if (!bannerShown.current) {
      return () => handleOpenBanner();
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    initialState();
  }, []);

  return (
    <>
      <section>
        <CustomCollapsible
          open={!!selectedInstance?.programed_excluding}
          action={openAction()}
          title={
            <h1 className="font-bold text-gray-333 text-[13px]">
              Programar exclusão
            </h1>
          }
        >
          <div className="mt-6 gap-4 flex flex-col">
            {["chats", "attachments"].map((type: any) => (
              <CustomCollapsible
                key={type}
                action={
                  !!selectedInstance?.programed_excluding?.[
                    type as keyof IProgramedExcluding
                  ]
                    ? () => handleDisable(type)
                    : undefined
                }
                open={
                  !!selectedInstance?.programed_excluding?.[
                    type as keyof IProgramedExcluding
                  ]
                }
                classname="p-4 rounded border border-gray-200"
                title={
                  <h1 className="font-bold text-gray-333 text-[13px]">
                    Regra de exclusão de{" "}
                    {type === "chats" ? "conversas" : "anexos"}
                  </h1>
                }
              >
                <ExcludingComponent
                  handleUpdate={handleUpdate}
                  selectedInstance={selectedInstance}
                  getInstance={getInstance}
                  type={type}
                />
              </CustomCollapsible>
            ))}
          </div>
        </CustomCollapsible>
      </section>
      {openBanner && (
        <DefaultModal
          buttons={{
            submitAction: handleCloseBanner,
            submitIcon: "las la-times",
            submitLabel: "Fechar",
          }}
          content={<ModalContent />}
          header={{
            icon: "las la-exclamation-circle",
            title: "Cuidado ao ativar uma exclusão programada.",
          }}
        />
      )}
    </>
  );
};

const ModalContent = () => (
  <div className="flex flex-col justify-center items-center my-2">
    <p className="font-bold text-gray-333 text-center my-6">
      A regra de exclusão criada pode ser editada ou desligada, porém as
      mensagens que forem excluídas não pode ser recuperadas <br />
      Confirme com seu time qual a regra de exclusão correta antes de cria-la e,
      em caso de dúvida, consulte nosso time de suporte
    </p>
  </div>
);

const ExcludingComponent = ({
  selectedInstance,
  handleUpdate,
  type,
}: ComponentExcludingProps) => {
  const dispatch = useAppDispatch();
  const { contacts } = useAppSelector((state) => state.users);
  const { customers } = useAppSelector((state) => state.customers);

  const [contactsResults, setContactsResults] = useState<IUser[]>([]);
  const [customersResults, setCustomersResults] = useState<ICustomer[]>([]);

  const firstRender = useRef(true);

  const handleSearchChange = async (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownOnSearchChangeData,
    type: "customers" | "contacts"
  ) => {
    const newResults = await fetchData(type, data.searchQuery, dispatch);
    if (type === "customers") {
      const updatedResults = [...customersResults, ...newResults];
      setCustomersResults(updatedResults);
      updateGlobalState(type, updatedResults, dispatch);
    } else {
      const updatedResults = [...contactsResults, ...newResults];
      setContactsResults(updatedResults);
      updateGlobalState(type, updatedResults, dispatch);
    }
  };

  const getInstances = async () => {
    if (firstRender.current) {
      if (selectedInstance?.programed_excluding?.[type]?.contacts) {
        const newContacts = await dispatch(
          getManyUsers({
            _ids: JSON.stringify(
              selectedInstance?.programed_excluding[type]?.contacts
            ),
          })
        );
        setContactsResults((prevResults) => {
          const updatedResults = [...prevResults, ...newContacts.payload];
          updateGlobalState("contacts", updatedResults, dispatch);
          return updatedResults;
        });
      }

      if (selectedInstance?.programed_excluding?.[type]?.customers) {
        const newCustomers = await dispatch(
          getManyCustomers({
            _ids: JSON.stringify(
              selectedInstance?.programed_excluding[type]?.customers
            ),
          })
        );

        setCustomersResults((prevResults) => {
          const updatedResults = [...prevResults, ...newCustomers.payload];
          updateGlobalState("customers", updatedResults, dispatch);
          return updatedResults;
        });
      }

      firstRender.current = false;
    }
  };

  useEffect(() => {
    setContactsResults(contacts);
    setCustomersResults(customers);
  }, [contacts, customers]);

  useEffect(() => {
    getInstances();
  }, [selectedInstance, type]);

  return (
    <Form className="mt-6 flex flex-col gap-4">
      <Form.Group widths="2" className="!m-0 gap-3">
        <Dropdown
          id={`programed_excluding.${type}.period`}
          className="w-full !m-0 !p-0"
          label="Tempo para exclusão"
          placeholder="Selecionar período"
          fluid
          search
          selection
          options={PERIOD}
          value={selectedInstance?.programed_excluding?.[type]?.period}
          onChange={handleUpdate}
        />

        <Dropdown
          className="w-full !m-0 !p-0 hidden md:opacity-0 md:block"
          options={[]}
        />
      </Form.Group>

      <Form.Group widths="2" className="!m-0 gap-3">
        <Dropdown
          id={`programed_excluding.${type}.customers`}
          disabled={!selectedInstance?.programed_excluding?.[type]?.period}
          className="w-full !p-0"
          label="Lista de exceções para clientes"
          placeholder="Selecionar clientes"
          fluid
          search
          multiple
          selection
          options={normalizeToOptions(customersResults)}
          value={selectedInstance?.programed_excluding?.[type]?.customers}
          onChange={handleUpdate}
          onSearchChange={(e, data) =>
            handleSearchChange(
              e,
              { searchQuery: data.searchQuery },
              "customers"
            )
          }
        />

        <Dropdown
          id={`programed_excluding.${type}.contacts`}
          disabled={!selectedInstance?.programed_excluding?.[type]?.period}
          className="w-full !m-0 !p-0"
          label="Lista de exceções para contatos"
          placeholder="Selecionar contatos"
          fluid
          search
          multiple
          selection
          options={normalizeToOptions(contactsResults)}
          value={selectedInstance?.programed_excluding?.[type]?.contacts}
          onChange={handleUpdate}
          onSearchChange={(e, data) =>
            handleSearchChange(e, { searchQuery: data.searchQuery }, "contacts")
          }
        />
      </Form.Group>
    </Form>
  );
};
