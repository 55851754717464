/* eslint-disable import/prefer-default-export */
import { ChatRead, IChat } from "../../../slices/chats.slice";

export const getChatReadArray = ({
  chat,
  userId,
  read,
}: {
  chat: IChat;
  userId: string;
  read: boolean;
}): ChatRead[] => {
  const _chatReadArray: ChatRead[] = [...(chat?.read ?? [])].map(
    (chatRead) => ({ ...chatRead })
  );
  if (_chatReadArray.length === 0) {
    _chatReadArray.push({ user: userId, read });
  } else {
    const userIndex = _chatReadArray.findIndex(
      (chatRead) => chatRead.user === userId
    );
    if (userIndex === -1) {
      _chatReadArray.push({ user: userId, read });
    } else {
      _chatReadArray[userIndex].read = read;
    }
  }
  return _chatReadArray;
};

export const checkChatReadInfo = ({
  chat,
  userId,
  read,
}: {
  chat: IChat;
  userId: string;
  read: boolean;
}): boolean => {
  const _chatReadArray: ChatRead[] = [...(chat?.read ?? [])];

  const _read =
    _chatReadArray.some(
      (chatRead) => chatRead.user === userId && chatRead.read === read
    ) || _chatReadArray.length === 0;

  return _read;
};
