import { memo, useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";
import { IMessage } from "../../../slices/messages.slice";
import { IChat } from "../../../slices/chats.slice";

interface IMessageCardListProps {
  message: IMessage;
  keyWord?: string;
  containerClass?: string;
  selectedChat?: IChat | null;
}

const MessageCardList = ({
  message,
  keyWord,
  containerClass,
  selectedChat,
}: IMessageCardListProps) => {
  const containerClassName = twJoin(
    "-ml-1 p-2 hover:bg-gray-200 cursor-pointer flex rounded-lg flex-col h-22 overflow-y-auto",
    selectedChat?._id === message?.chat?._id ? "bg-gray-200 shadow-sm" : "",
    containerClass
  );

  return (
    <div className={containerClassName}>
      <h1 className="font-semibold mb-1">{message.sender.name}</h1>
      <p className="h-16 overflow-hidden ellipsis">
        <HighlightText text={message.content} highlight={keyWord} />
      </p>
    </div>
  );
};

const HighlightText = ({
  text,
  highlight,
}: {
  text: string | undefined;
  highlight: string | undefined;
}) => {
  const highlightRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (highlightRef.current) {
      const parent = highlightRef.current.parentElement;
      const isVisible = parent && parent.scrollHeight > parent.clientHeight;
      if (isVisible) {
        highlightRef.current.scrollIntoView({
          block: "start",
        });
      }
    }
  }, [highlight]);

  if (!highlight) {
    return <span>{text}</span>;
  }

  const parts = text?.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <span>
      {parts?.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} ref={highlightRef} className="bg-yellow-200">
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};

export default memo(MessageCardList);
