/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import { DropdownSearchInputProps } from "semantic-ui-react";
import DropdownComponent from "../Dropdown.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import { externalappsService } from "../../../services";
import Button from "../Buttons/Button.component";
import ServicesDisplayComponent, {
  dataProps,
  // dataRelationProps,
} from "./ServicesDisplay.component";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import {
  IAgideskNewTicket,
  IAgideskEntity,
  reselectExternalappAgidesk,
} from "../../../slices/externalapps.slice";
import removeDuplicates from "../../utils/removeDuplicates";

const CategoriesTopicsSelectionComponent = ({
  type,
  relation,
  setRelation,
  ticket,
  setTicket,
}: {
  type?:
    | "agidesk-ticket-create"
    | "agidesk-ticket-finish"
    | "agidesk-ticket-finish-inactive"
    | "chat-new-ticket"
    | "team-service-relation";
  relation?: {
    team: string;
    service: IAgideskEntity;
  };
  setRelation?: React.Dispatch<
    React.SetStateAction<{
      team: string;
      service: IAgideskEntity;
    }>
  >;
  ticket?: IAgideskNewTicket;
  setTicket?: React.Dispatch<React.SetStateAction<IAgideskNewTicket>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const dispatch = useAppDispatch();
  const { selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );

  const [values, setValues] = useState<{
    category: string;
    topic: string;
    teams: string[];
    services: string[];
  }>({
    category: "",
    topic: "",
    teams: [],
    services: [],
  });

  const [options, setOptions] = useState<{
    categories: ddOptions[];
    topics: ddOptions[];
    teams: ddOptions[];
    services: ddOptions[];
  }>({
    categories: [],
    topics: [],
    teams: [],
    services: [],
  });

  const [short, setShort] = useState<boolean>(true);
  const [data, setData] = useState<dataProps[]>([]);
  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);

  const clearValues = () => {
    setValues({
      category: "",
      topic: "",
      services: [],
      teams: [],
    });
  };

  const clearOptions = () => {
    setOptions({
      categories: [],
      topics: [],
      teams: [],
      services: [],
    });
  };

  useEffect(() => {
    if (
      type &&
      [
        "agidesk-ticket-create",
        "team-service-relation",
        "agidesk-ticket-finish",
        "agidesk-ticket-finish-inactive",
      ].includes(type)
    ) {
      const _data: dataProps[] = [];
      if (type === "agidesk-ticket-create") {
        selectedExternalAppAgidesk?.fields?.service?.forEach((_service) => {
          _data.push({
            ..._data,
            service: { ..._service },
          });
        });
      }

      if (
        type === "agidesk-ticket-finish" &&
        typeof selectedExternalAppAgidesk?.fields?.ticketfinishservice?.id !==
          "undefined"
      ) {
        _data.push({
          service: {
            ...selectedExternalAppAgidesk?.fields?.ticketfinishservice,
          },
        });
      }

      if (
        type === "agidesk-ticket-finish-inactive" &&
        typeof selectedExternalAppAgidesk?.fields?.ticketfinishinactiveservice
          ?.id !== "undefined"
      ) {
        _data.push({
          service: {
            ...selectedExternalAppAgidesk?.fields?.ticketfinishinactiveservice,
          },
        });
      }
      setData(_data);
    }

    if (
      type === "agidesk-ticket-finish" &&
      typeof selectedExternalAppAgidesk?.fields?.ticketfinishservice?.id !==
        "undefined"
    ) {
      setOptions((prevState) => ({
        ...prevState,
        services: [
          {
            key: selectedExternalAppAgidesk.fields.ticketfinishservice.id,
            text: selectedExternalAppAgidesk.fields.ticketfinishservice.title,
            value: selectedExternalAppAgidesk.fields.ticketfinishservice.id,
          },
        ],
      }));
      setValues((prevState) => ({
        ...prevState,
        services: [selectedExternalAppAgidesk?.fields?.ticketfinishservice?.id],
      }));
    }

    if (
      type === "agidesk-ticket-finish-inactive" &&
      typeof selectedExternalAppAgidesk?.fields?.ticketfinishinactiveservice
        ?.id !== "undefined"
    ) {
      setOptions((prevState) => ({
        ...prevState,
        services: [
          {
            key: selectedExternalAppAgidesk.fields.ticketfinishinactiveservice
              .id,
            text: selectedExternalAppAgidesk.fields.ticketfinishinactiveservice
              .title,
            value:
              selectedExternalAppAgidesk.fields.ticketfinishinactiveservice.id,
          },
        ],
      }));
      setValues((prevState) => ({
        ...prevState,
        services: [selectedExternalAppAgidesk?.fields?.ticketfinishservice?.id],
      }));
    }
    if (type === "team-service-relation") {
      clearOptions();
      clearValues();
      document
        .querySelectorAll("#SERVICE-team-service-relation .clear")
        .forEach((el: any) => el.click());
    }
    if (type === "chat-new-ticket") {
      clearOptions();
      clearValues();
      if (typeof ticket !== "undefined" && typeof setTicket !== "undefined") {
        setTicket({
          ...ticket,
          services: "",
        });
      }
    }
  }, [selectedExternalAppAgidesk, short]);

  const getServicecategoryDropdown = () => {
    const getCategories = async (filter: string) => {
      const payload = await externalappsService.searchAgideskServicecategories({
        skip: 0,
        limit: 100 + options.categories.length,
        filter,
        externalappid: selectedExternalAppAgidesk._id,
        main: type !== "chat-new-ticket" ? true : undefined,
      });
      return payload;
    };

    const setCategories = (payload: any, _options: any) => {
      payload?.forEach((category: { id: string; fulltitle: string }) => {
        if (
          !_options
            .map((_opCategory: any) => _opCategory.key)
            .includes(category.id)
        )
          _options.push({
            key: category.id,
            text: category.fulltitle,
            value: category.id,
          });
      });
      setOptions((prevState) => ({
        ...prevState,
        categories: _options,
      }));
    };

    const onChangeServicecategory = (value: any) => {
      if (value) {
        setOptions((prevState) => ({
          ...prevState,
          topics: [],
          services: [],
        }));
        setValues((prevState) => ({
          ...prevState,
          category: value.toString(),
          topic: "",
          services: [],
        }));
      }
    };

    return (
      <DropdownComponent
        id={`CATEGORY-${type}`}
        loading={isLoadingDropdown && focusId === `CATEGORY-${type}`}
        onFocus={() => {
          setFocusId(`CATEGORY-${type}`);
        }}
        onBlur={() => {
          setFocusId("");
        }}
        className="mt-2 items-center w-full"
        label="Seção"
        placeholder="Seção"
        required
        fluid
        search
        selection
        options={options.categories}
        value={values.category}
        onSearchChange={async (e: DropdownSearchInputProps) => {
          setIsLoadingDropdown(true);
          const payload = await getCategories(e.target.value);
          setIsLoadingDropdown(false);
          const _optionsCategories: ddOptions[] = [...options.categories];
          setCategories(payload, _optionsCategories);
        }}
        onOpen={async () => {
          setIsLoadingDropdown(true);
          const payload = await getCategories("");
          setIsLoadingDropdown(false);
          setCategories(payload, []);
        }}
        onChange={(e, { value }) => {
          onChangeServicecategory(value);
        }}
      />
    );
  };

  const getServicetopicDropdown = () => {
    const getTopics = async (filter: string) => {
      const payload = await externalappsService.searchAgideskServicetopics({
        skip: 0,
        limit: 100 + options.topics.length,
        filter,
        externalappid: selectedExternalAppAgidesk._id,
        main: type !== "chat-new-ticket" ? true : undefined,
        categoryid: values.category,
      });
      return payload;
    };

    const setTopics = (payload: any, _options: any) => {
      payload?.forEach((topic: { id: string; title: string }) => {
        if (!_options.map((_opTopic: any) => _opTopic.key).includes(topic.id))
          _options.push({
            key: topic.id,
            text: topic.title,
            value: topic.id,
          });
      });
      setOptions((prevState) => ({
        ...prevState,
        topics: _options,
      }));
    };

    const onChangeServicetopic = (value: any) => {
      if (value) {
        setOptions((prevState) => ({
          ...prevState,
          services: [],
        }));
        setValues((prevState) => ({
          ...prevState,
          topic: value.toString(),
          services: [],
        }));
      }
    };

    return (
      <DropdownComponent
        id={`TOPIC-${type}`}
        loading={isLoadingDropdown && focusId === `TOPIC-${type}`}
        onFocus={() => {
          setFocusId(`TOPIC-${type}`);
        }}
        onBlur={() => {
          setFocusId("");
        }}
        className="mt-2 items-center w-full"
        label="Tópico"
        placeholder="Tópico"
        required
        fluid
        search
        selection
        options={options.topics}
        value={values.topic}
        disabled={values.category.length === 0}
        onSearchChange={async (e: DropdownSearchInputProps) => {
          setIsLoadingDropdown(true);
          const payload = await getTopics(e.target.value);
          setIsLoadingDropdown(false);
          const _optionsTopics: ddOptions[] = [...options.topics];
          setTopics(payload, _optionsTopics);
        }}
        onOpen={async () => {
          setIsLoadingDropdown(true);
          const payload = await getTopics("");
          setIsLoadingDropdown(false);
          setTopics(payload, []);
        }}
        onChange={(e, { value }) => {
          onChangeServicetopic(value);
        }}
      />
    );
  };

  const getServiceDropdown = () => {
    const getServices = async (filter: string) => {
      const payload = await externalappsService.searchAgideskServices({
        skip: 0,
        limit: 100 + options.topics.length,
        filter,
        externalappid: selectedExternalAppAgidesk._id,
        main: type !== "chat-new-ticket" ? true : undefined,
        topicid: !short ? values.topic : undefined,
      });
      return payload;
    };

    const setServices = (payload: any, _options: any) => {
      payload?.forEach(
        (service: { id: string; fullservice: string; topics?: any }) => {
          if (
            !_options
              .map((_opService: any) => _opService.key)
              .includes(service.id)
          )
            _options.push({
              key: service.id,
              text: service.fullservice,
              value: service.id,
              contentinfo: {
                topic: Object.keys(service.topics)[0],
                category: Object.keys(
                  (Object.values(service.topics)[0] as any).categories
                )[0],
              },
            });
        }
      );
      setOptions((prevState) => ({
        ...prevState,
        services: _options,
      }));
    };

    const onChangeService = (value: any) => {
      if (value) {
        setValues((prevState) => ({
          ...prevState,
          services: value as string[],
        }));
        const _data: dataProps[] =
          type === "agidesk-ticket-create" ? [...data] : [];
        const _keys = new Set(_data.map((_d) => _d.service?.id));
        switch (type) {
          case "agidesk-ticket-create":
            options.services.forEach((_service) => {
              if (
                values.services.includes(_service.key as string) &&
                !_keys.has(_service.key as string)
              ) {
                _data.push({
                  ..._data,
                  service: {
                    id: _service.key as string,
                    title: _service.text as string,
                  },
                });
                _keys.add(_service.key as string);
              }
            });
            break;
          case "team-service-relation":
            if (
              typeof setRelation !== "undefined" &&
              typeof relation !== "undefined"
            ) {
              options.services.forEach((_service) => {
                if ((value as string[]).includes(_service.key as string)) {
                  if (typeof _service.key !== "undefined") {
                    setRelation({
                      ...relation,
                      service: {
                        id: _service.key || "",
                        title: _service.text || "",
                      },
                    });
                  }
                }
              });
            }
            break;
          case "chat-new-ticket":
            if (
              typeof setTicket !== "undefined" &&
              typeof ticket !== "undefined"
            ) {
              setTicket({
                ...ticket,
                services: value.toString(),
              });
            }
            break;
          case "agidesk-ticket-finish-inactive":
            options.services.forEach((_service) => {
              if ((value as string[]).includes(_service.key as string)) {
                _data.push({
                  service: {
                    id: _service.key as string,
                    title: _service.text as string,
                  },
                });
              }
            });
            setData(_data);
            dispatch(
              reselectExternalappAgidesk({
                ...selectedExternalAppAgidesk,
                fields: {
                  ...selectedExternalAppAgidesk.fields,
                  ticketfinishinactiveservice: _data.map(
                    (item) => item.service
                  )[0] as any,
                },
              })
            );
            break;
          case "agidesk-ticket-finish":
          default:
            options.services.forEach((_service) => {
              if ((value as string[]).includes(_service.key as string)) {
                _data.push({
                  service: {
                    id: _service.key as string,
                    title: _service.text as string,
                  },
                });
              }
            });
            setData(_data);
            dispatch(
              reselectExternalappAgidesk({
                ...selectedExternalAppAgidesk,
                fields: {
                  ...selectedExternalAppAgidesk.fields,
                  ticketfinishservice: _data.map(
                    (item) => item.service
                  )[0] as any,
                },
              })
            );
            break;
        }
      }
    };

    return (
      <DropdownComponent
        id={`SERVICE-${type}`}
        loading={isLoadingDropdown && focusId === `SERVICE-${type}`}
        onFocus={() => {
          setFocusId(`SERVICE-${type}`);
        }}
        onBlur={() => {
          setFocusId("");
        }}
        clearable={type === "team-service-relation"}
        multiple={type === "agidesk-ticket-create"}
        className="mt-2 items-center w-full"
        label="Serviço"
        placeholder="Serviço"
        disabled={
          selectedExternalAppAgidesk?.deleted || !isAdmin || !short
            ? values.topic.length === 0
            : false
        }
        required
        fluid
        search
        selection
        options={options.services}
        value={type === "agidesk-ticket-create" ? values.services : undefined}
        defaultValue={
          type === "agidesk-ticket-finish"
            ? selectedExternalAppAgidesk?.fields?.ticketfinishservice?.id
            : type === "agidesk-ticket-finish-inactive"
            ? selectedExternalAppAgidesk?.fields?.ticketfinishinactiveservice
                ?.id
            : undefined
        }
        onSearchChange={async (e: DropdownSearchInputProps) => {
          setIsLoadingDropdown(true);
          const payload = await getServices(e.target.value);
          setIsLoadingDropdown(false);
          const _optionsServices: ddOptions[] = [...options.services];
          setServices(payload, _optionsServices);
        }}
        onOpen={async () => {
          setIsLoadingDropdown(true);
          const payload = await getServices("");
          setIsLoadingDropdown(false);
          setServices(payload, []);
        }}
        onChange={(e, { value }) => {
          onChangeService(value);
        }}
      />
    );
  };

  const changeServicesMode = () => {
    setShort((prevState) => !prevState);
    if (type === "agidesk-ticket-finish") {
      clearValues();
      dispatch(
        reselectExternalappAgidesk({
          ...selectedExternalAppAgidesk,
          fields: {
            ...selectedExternalAppAgidesk.fields,
            ticketfinishservice: { id: "", title: "" },
          },
        })
      );
    }

    if (type === "agidesk-ticket-finish-inactive") {
      clearValues();
      dispatch(
        reselectExternalappAgidesk({
          ...selectedExternalAppAgidesk,
          fields: {
            ...selectedExternalAppAgidesk.fields,
            ticketfinishinactiveservice: { id: "", title: "" },
          },
        })
      );
    }
  };

  const addServicesAction = () => {
    const _data: dataProps[] = [...data];
    const _categoryArray = [
      selectedExternalAppAgidesk?.fields?.category,
      ...[values.category],
    ].filter((_value) => typeof _value !== undefined && _value);
    const _topicArray = [
      selectedExternalAppAgidesk?.fields?.topic,
      ...[values.topic],
    ].filter((_value) => typeof _value !== undefined && _value);
    const _keys = new Set(_data.map((_d) => _d.service?.id));
    options.services.forEach((_service) => {
      if (
        values.services.includes(_service.key as string) &&
        !_keys.has(_service.key as string)
      ) {
        _categoryArray.push((_service.contentinfo as any).category);
        _topicArray.push((_service.contentinfo as any).topic);
        _data.push({
          service: {
            id: _service.key as string,
            title: _service.text as string,
          },
        });
        _keys.add(_service.key as string);
      }
    });
    clearOptions();
    clearValues();
    const _sortData = (_data as any).sort((a: any, b: any) => {
      if (a?.service?.title < b?.service?.title) {
        return -1;
      }
      if (a?.service?.title > b?.service?.title) {
        return 1;
      }
      return 0;
    });
    setData(_sortData);
    const _serviceData = _sortData.map((item: any) => item.service);
    const _category = removeDuplicates(_categoryArray.flat(Infinity));
    const _topic = removeDuplicates(_topicArray.flat(Infinity));
    dispatch(
      reselectExternalappAgidesk({
        ...selectedExternalAppAgidesk,
        fields: {
          ...selectedExternalAppAgidesk.fields,
          service: _serviceData,
          category: _category,
          topic: _topic,
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-y-2 border bg-slate-50 rounded-[4px] p-4 mt-2 mb-4 w-full">
      <div className="flex flex-col w-full gap-y-4 relative">
        {short ? (
          getServiceDropdown()
        ) : (
          <>
            {getServicecategoryDropdown()}
            {getServicetopicDropdown()}
            {getServiceDropdown()}
          </>
        )}
      </div>
      <div className="my-2 flex gap-4 justify-end">
        <Button
          inverted
          disabled={false}
          minWidth
          label={
            short ? "Selecionar seção e tópico" : "Selecionar em campo único"
          }
          onClick={changeServicesMode}
        />
        {type === "agidesk-ticket-create" ? (
          <Button
            disabled={values.services.length === 0}
            minWidth
            icon="las la-plus"
            label="Adicionar"
            title="Adicionar serviços"
            onClick={addServicesAction}
          />
        ) : null}
      </div>
      {type && ["agidesk-ticket-create"].includes(type) ? (
        <ServicesDisplayComponent
          data={data}
          setData={setData}
          type="service"
        />
      ) : null}
    </div>
  );
};

export default CategoriesTopicsSelectionComponent;
