import { twMerge } from "tailwind-merge";

interface CustomCollapsibleProps {
  title: string;
  classname?: string;
  icon?: string;
  background?: string;
}

const Badge = ({
  title,
  classname,
  icon,
  background = "bg-agidesk",
}: CustomCollapsibleProps) => {
  return (
    <span
      className={twMerge(
        "w-max px-2 py-[3px] flex items-center justify-center rounded-full text-white text-[11px]",
        background,
        classname
      )}
    >
      {icon && <i className={twMerge(icon, "mr-4 text-4xl")} />}
      {title}
    </span>
  );
};

export default Badge;
