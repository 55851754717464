/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from "react";
import { formatRelative, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import NotificationBadge from "./NotificationBadge.component";
import {
  ChatType,
  IChat,
  IChatCard,
  removeFromRecents,
} from "../../../slices/chats.slice";
import { IUser, RoleUser, UserStatus } from "../../../slices/users.slice";
import useFormatText from "../../../hooks/useFormatText";
import Tag from "./Tag.component";
import TagArea from "./TagArea.component";
import handleMaskLength from "../../../shared/utils/handleMaskLength";
import CloseButton from "../../../shared/components/Buttons/CloseButton.component";
import NoAvatar from "../../../shared/components/NoAvatar.component";
import stripTags from "../../../shared/utils/stripTags";
// import instances from "../../../services/instances";
import Avatar from "../../../shared/components/Avatar.component";
import useSanitizeText from "../../../hooks/useSanitizeText";
import {
  IChatPreferences,
  prepareDraftThumbs,
} from "../../../slices/preferences.slice";
import { checkChatReadInfo } from "./utils";
// import PopupComponent from "../../../shared/components/Popup.component";

const ChatCard = ({
  chat,
  notifications,
  recents,
  historyMode,
  disabled,
  disabledClick,
}: IChatCard) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // const URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAppSelector((state) => state.auth);
  const { recentChats, mineChats, selectedChat } = useAppSelector(
    (state) => state.chats
  );

  const chatsPreferences = useAppSelector((state) => {
    const { preferences } = state.preferences;
    const index = preferences?.findIndex((object) => object.user === user?._id);

    return typeof preferences !== "undefined" &&
      typeof index !== "undefined" &&
      index !== -1
      ? preferences[index].chats
      : undefined;
  });

  const [actions, setActions] = useState<{
    whoIsTalking: string | undefined;
    isTyping: boolean;
    isRecording: boolean;
  }>({
    whoIsTalking: "",
    isTyping: false,
    isRecording: false,
  });

  const includedInGroup =
    chat.groupchat &&
    chat?.groupsettings
      ?.filter((_gs) => _gs.active === true)
      ?.map((_u) =>
        typeof _u.user?._id !== "undefined" ? _u.user?._id : _u.user
      )
      .includes(user?._id);
  const userInGroup = chat?.groupsettings?.find((_gs) =>
    typeof _gs.user?._id !== "undefined"
      ? _gs.user?._id === user?._id
      : _gs.user === user?._id
  );
  const _user = chat?.users?.find((u: IUser) => u._id !== user?._id) || null;

  const [cardItem, setCard] = useState({
    id: chat._id,
    read: chat?.read,
    badge: typeof notifications !== "undefined" ? notifications : 0,
    team: chat.team,
    lastmessage: {
      commenttemplate: chat.lastmessage?.commenttemplate,
      internal: chat.lastmessage?.internal || false,
      sender: chat.lastmessage?.sender,
      content: chat.lastmessage?.content,
      stamp: chat.lastmessage?.created_at,
      hasFiles:
        typeof chat.lastmessage?.files !== "undefined"
          ? chat.lastmessage?.files?.length > 0
          : false,
    },
    tags: {
      campaign: chat?.campaign?.campaign,
      offline: chat.offline,
      group: chat.groupchat,
      type: chat.type,
      status: chat.status,
      externalapp: chat.externalapp,
      transferred: chat.transferred,
      custom: chat.tags,
    },
    notAllowed: chat.groupchat && !includedInGroup,
    group: {
      groupchat: chat.groupchat || false,
      name:
        !chat.groupchat || (chat.groupchat && includedInGroup)
          ? chat?.name || ""
          : userInGroup?.chatname || "",
      avatar:
        !chat.groupchat || (chat.groupchat && includedInGroup)
          ? chat?.avatar
          : userInGroup?.chatavatar,
    },
    user: {
      name: _user?.name || "",
      phone: _user?.phone || "",
      status: _user?.status || UserStatus.OFFLINE,
      roles: _user?.roles,
      avatar: _user?.avatar,
      team:
        typeof _user?.teams !== "undefined" &&
        _user?.teams?.length > 0 &&
        typeof _user.teams[0]?.name !== "undefined"
          ? `${_user.teams[0]?.name}${
              _user.teams.length > 1
                ? ` (e mais ${_user.teams.length - 1})`
                : ""
            }`
          : "",
      customer:
        typeof _user?.customers !== "undefined" &&
        _user?.customers?.length > 0 &&
        typeof _user.customers[0]?.name !== "undefined"
          ? `${_user.customers[0]?.name}${
              _user.customers.length > 1
                ? ` (e mais ${_user.customers.length - 1})`
                : ""
            }`
          : "",
    },
  });

  useEffect(() => {
    setCard({
      id: chat._id,
      read: chat?.read,
      badge: typeof notifications !== "undefined" ? notifications : 0,
      team: chat.team,
      lastmessage: {
        commenttemplate: chat.lastmessage?.commenttemplate,
        internal: chat.lastmessage?.internal || false,
        sender: chat.lastmessage?.sender,
        content: chat.lastmessage?.content,
        stamp: chat.lastmessage?.created_at,
        hasFiles:
          typeof chat.lastmessage?.files !== "undefined"
            ? chat.lastmessage?.files?.length > 0
            : false,
      },
      tags: {
        campaign: chat?.campaign?.campaign,
        offline: chat.offline,
        group: chat.groupchat,
        type: chat.type,
        status: chat.status,
        externalapp: chat.externalapp,
        transferred: chat.transferred,
        custom: chat.tags,
      },
      notAllowed: chat.groupchat && !includedInGroup,
      group: {
        groupchat: chat.groupchat || false,
        name:
          !chat.groupchat || (chat.groupchat && includedInGroup)
            ? chat?.name || ""
            : userInGroup?.chatname || "",
        avatar:
          !chat.groupchat || (chat.groupchat && includedInGroup)
            ? chat?.avatar
            : userInGroup?.chatavatar,
      },
      user: {
        name: _user?.name || "",
        phone: _user?.phone || "",
        status: _user?.status || UserStatus.OFFLINE,
        roles: _user?.roles,
        avatar: _user?.avatar,
        team:
          typeof _user?.teams !== "undefined" &&
          _user?.teams?.length > 0 &&
          typeof _user.teams[0]?.name !== "undefined"
            ? `${_user.teams[0]?.name}${
                _user.teams.length > 1
                  ? ` (e mais ${_user.teams.length - 1})`
                  : ""
              }`
            : "",
        customer:
          typeof _user?.customers !== "undefined" &&
          _user?.customers?.length > 0 &&
          typeof _user.customers[0]?.name !== "undefined"
            ? `${_user.customers[0]?.name}${
                _user.customers.length > 1
                  ? ` (e mais ${_user.customers.length - 1})`
                  : ""
              }`
            : "",
      },
    });
  }, [chat, notifications, recents, historyMode, disabled, disabledClick]);

  useEffect(() => {
    if (recents) {
      setActions({
        ...actions,
        isTyping:
          recentChats.find((c: IChat) => c._id === cardItem.id)?.istyping ===
          true,
        isRecording:
          recentChats.find((c: IChat) => c._id === cardItem.id)
            ?.isrecordaudio === true,
        whoIsTalking:
          typeof recentChats.find((c: IChat) => c._id === cardItem.id)
            ?.whoIsTalking !== "undefined"
            ? recentChats.find((c: IChat) => c._id === cardItem.id)
                ?.whoIsTalking
            : "",
      });
    } else {
      setActions({
        ...actions,
        isTyping:
          mineChats.find((c: IChat) => c._id === cardItem.id)?.istyping ===
          true,
        isRecording:
          mineChats.find((c: IChat) => c._id === cardItem.id)?.isrecordaudio ===
          true,
        whoIsTalking:
          typeof mineChats.find((c: IChat) => c._id === cardItem.id)
            ?.whoIsTalking !== "undefined"
            ? mineChats.find((c: IChat) => c._id === cardItem.id)?.whoIsTalking
            : "",
      });
    }
  }, [
    actions.isRecording,
    actions.isTyping,
    actions.whoIsTalking,
    mineChats,
    recentChats,
    selectedChat,
    cardItem,
  ]);

  const getLastMessageContent = () => {
    const getContent = () => {
      const prepareContent = () => {
        if (
          typeof cardItem?.lastmessage?.content !== "undefined" &&
          cardItem.lastmessage.content.length > 0
        ) {
          let _msg = cardItem?.lastmessage?.content;
          // eslint-disable-next-line no-useless-escape
          const regex = /[^\[]+(?=\])/g;
          const matched = _msg.match(regex);

          if (
            cardItem?.lastmessage?.internal &&
            typeof cardItem?.group?.groupchat !== "undefined" &&
            cardItem?.group?.groupchat &&
            typeof matched !== "undefined" &&
            matched &&
            matched.length > 0
          ) {
            matched.forEach((i) => {
              const __user = chat?.groupsettings?.find((u) =>
                typeof u.user?._id !== "undefined"
                  ? u.user?._id === i
                  : (u.user as any) === i
              );
              if (
                typeof __user?.user?.name !== "undefined" &&
                typeof __user?.user?._id !== "undefined"
              ) {
                const _value =
                  __user?.user?._id === user?._id ? "você" : __user?.user?.name;
                _msg = _msg.replace(`[${i}]`, _value);
              }
            });
            const _startsCapital = _msg[3].toUpperCase();
            return _msg.replace(_msg[3], _startsCapital);
            // .replaceAll("<b>", "<b class='text-agitalks'>")
          }
          if (
            cardItem?.lastmessage?.commenttemplate !== "undefined" &&
            cardItem?.lastmessage?.commenttemplate &&
            cardItem?.lastmessage?.commenttemplate.length > 0
          ) {
            return stripTags({ value: _msg });
          }
          return _msg;
        }
        if (
          typeof cardItem?.lastmessage?.hasFiles !== "undefined" &&
          cardItem?.lastmessage?.hasFiles
        )
          return t("messages.file");
        return "";
      };
      const response = prepareContent();
      // console.log('response', cardItem?.lastmessage.internal);
      return !cardItem?.lastmessage?.internal
        ? useSanitizeText({
            text: response,
            stripTagsExceptions: ["app-externalapp-htmlmessage"],
          })
        : response;
    };

    if (cardItem.lastmessage && cardItem?.lastmessage?.sender && !historyMode) {
      if (
        cardItem?.lastmessage?.sender?._id === user?._id &&
        !cardItem.lastmessage.internal
      ) {
        return `${t("messages.mine")} ${getContent()}`;
      }
      if (
        cardItem.group.groupchat &&
        !cardItem.lastmessage.internal &&
        typeof cardItem.lastmessage.sender?.name !== "undefined"
      ) {
        return `${cardItem.lastmessage.sender.name}: ${getContent()}`;
      }
      return getContent();
    }
    return "";
  };

  const handleDraftThumbs = (chatPreferences: IChatPreferences) => {
    if (typeof chatPreferences !== "undefined" && chatPreferences !== null) {
      const preparedThumbs = prepareDraftThumbs(chatPreferences);
      return typeof preparedThumbs !== "undefined" && preparedThumbs.length > 0
        ? preparedThumbs
        : undefined;
    }
  };

  const getDraftMessage = () => {
    if (typeof chatsPreferences !== "undefined") {
      const _index = chatsPreferences?.findIndex(
        (object) => object._id === cardItem?.id
      );
      const hasPreferences = typeof _index !== "undefined" && _index !== -1;
      const draftMessage = hasPreferences
        ? chatsPreferences[_index].draftMessage
        : undefined;
      const draftThumb = handleDraftThumbs(chatsPreferences[_index]);

      const draftContent =
        typeof draftMessage !== "undefined" && draftMessage !== ""
          ? draftMessage.length > 10
            ? `${draftMessage.substring(0, 20)}...`
            : draftMessage
          : typeof draftThumb !== "undefined" && draftThumb.length > 0
          ? t("messages.file")
          : undefined;

      if (
        typeof draftContent !== "undefined" &&
        selectedChat?._id !== cardItem.id
      ) {
        const renderHTML = (rawHTML: string) =>
          React.createElement("span", {
            dangerouslySetInnerHTML: { __html: rawHTML },
          });
        return (
          <div className="items-center italic text-agitalks">
            <span className="text-red pr-1"> {t("messages.draft")}</span>
            {renderHTML(useFormatText(stripTags({ value: draftContent })))}
          </div>
        );
      }
    }
  };

  const getLastMessage = () => {
    if (cardItem.notAllowed) {
      return (
        <div className="flex items-center italic text-agitalks">
          <span>Você não participa mais deste grupo.</span>
        </div>
      );
    }
    if ((actions.isRecording || actions.isTyping) && !historyMode) {
      return (
        <div className="flex items-center italic text-agitalks">
          <span>
            {cardItem.group.groupchat &&
            typeof actions.whoIsTalking !== "undefined"
              ? `${actions.whoIsTalking} `
              : ""}
            {actions.isRecording
              ? t("messages.recording-audio")
              : t("messages.typing")}
          </span>
        </div>
      );
    }
    const draftContent = getDraftMessage();
    if (typeof draftContent !== "undefined") {
      return draftContent;
    }
    return (
      <span
        className="word-wrap-2"
        dangerouslySetInnerHTML={{
          __html: getLastMessageContent(),
        }}
      />
    );
  };

  const formatRelativeLocale = (token: string) => {
    switch (token) {
      case "lastWeek":
        return "eeee";
      case "yesterday":
        return `'${t("time.yesterday")}'`;
      case "today":
        return "HH:mm";
      default:
        return "dd/MM/yyyy";
    }
  };

  const locale = {
    ...ptBR,
    formatRelative: (token: string) => formatRelativeLocale(token),
  };

  const getTitle = () => {
    const _name = cardItem.group.groupchat
      ? cardItem.group.name
      : cardItem.user.name;
    if (typeof _name !== "undefined" && _name?.length > 0) {
      return (
        <span
          className={`text-semibold ${
            disabled ? "text-gray-text" : "text-gray-333"
          } ${recents ? "text-[11px]" : "text-[14px]"} word-wrap-2`}
          dangerouslySetInnerHTML={{
            __html: `<b>${_name}</b>`,
          }}
        />
      );
    }
    return (
      <ReactInputMask
        style={{
          background: "transparent",
          cursor: "pointer",
          fontWeight: "bold",
          wordWrap: "break-word",
          fontSize: recents ? "11px" : "14px",
        }}
        maskPlaceholder={null}
        mask={handleMaskLength({ value: cardItem.user.phone })}
        value={cardItem.user.phone}
        readOnly
        disabled={disabled || disabledClick}
      />
    );
  };

  const containerClass = recents ? "w-[190px]" : "max-h-[250px]";

  const getDateLabel = () => {
    // const _agent = chat?.users?.find(
    //   (__user) =>
    //     __user.roles?.includes(RoleUser.AGENT) && __user._id !== user?._id
    // );

    // if (
    //   !historyMode &&
    //   !cardItem.group.groupchat &&
    //   chat.type !== ChatType.INTERNAL &&
    //   typeof _agent?._id !== "undefined" &&
    //   _agent?.status === UserStatus.OFFLINE
    // ) {
    //   return (
    //     <h2 className="text-[10px] max-w-[95px] text-gray-777 font-normal">
    //       <PopupComponent
    //         red
    //         className="flex items-center"
    //         label={
    //           typeof cardItem?.lastmessage?.stamp !== "undefined"
    //             ? formatRelative(
    //                 parseISO(cardItem?.lastmessage?.stamp),
    //                 new Date(),
    //                 {
    //                   locale,
    //                 }
    //               )
    //             : ""
    //         }
    //         content={
    //           <div>
    //             <h1 className="font-semibold text-[12px]">
    //               Agente {_agent?.name} parece estar off-line.
    //             </h1>
    //             <h1 className="font-semibold text-[12px]">
    //               Capture esta conversa para seguir com o atendimento.
    //             </h1>
    //           </div>
    //         }
    //       />
    //     </h2>
    //   );
    // }
    return (
      <h2 className="text-[10px] max-w-[95px] text-gray-777">
        {typeof cardItem?.lastmessage?.stamp !== "undefined"
          ? formatRelative(parseISO(cardItem?.lastmessage?.stamp), new Date(), {
              locale,
            })
          : ""}
      </h2>
    );
  };

  const getCustomTags = () => {
    if (
      typeof cardItem.tags.custom === "undefined" ||
      cardItem.tags.custom.length === 0
    ) {
      return null;
    }
    if (cardItem.tags.custom.length > 2) {
      const _tags = cardItem.tags.custom;
      const _labels = cardItem.tags.custom.map((_tag) => _tag.name).toString();
      return (
        <>
          <Tag key={_tags[0]._id} tagType="custom" customTag={_tags[0]} />
          <Tag key={_tags[1]._id} tagType="custom" customTag={_tags[1]} />
          <h2
            title={_labels}
            className="text-[11px] mt-1 text-gray-333 font-semibold"
          >{`e mais ${_tags.length - 2}`}</h2>
        </>
      );
    }
    return cardItem.tags.custom.map((_tag) => (
      <Tag key={_tag._id} tagType="custom" customTag={_tag} />
    ));
  };

  const getIfChatIsRead = () => {
    const chatusersIncludeMe: boolean =
      chat?.users?.some((_u) => _u._id === user?._id) || false;
    const chatteamIncludeMine: boolean =
      user?.teams?.some(
        (_t) => _t._id === chat?.team?._id || _t._id === chat?.team
      ) || false;

    const _read = checkChatReadInfo({
      chat,
      userId: user?._id as string,
      read: true,
    });

    if (typeof chatsPreferences !== "undefined") {
      const _index = chatsPreferences?.findIndex(
        (object) => object._id === cardItem?.id
      );
      const hasPreferences = typeof _index !== "undefined" && _index !== -1;
      if (
        hasPreferences &&
        typeof chatsPreferences[_index].read === "boolean"
      ) {
        return chatsPreferences[_index].read;
      }
    }
    if (
      typeof _read === "boolean" &&
      (chatusersIncludeMe || chatteamIncludeMine)
    ) {
      return _read;
    }
    return true;
  };

  const _avatar = cardItem.group.groupchat
    ? cardItem.group.avatar
    : cardItem.user.avatar;
  const _name = cardItem.group.groupchat
    ? cardItem.group.name
    : cardItem.user.name;
  return (
    <div
      title={`${disabled ? "Sem permissão" : ""}`}
      className={`${disabled ? "bg-red-alpha20 hover:bg-red-alpha20" : ""}
      ${selectedChat?._id === cardItem.id ? "bg-gray-200 shadow-sm" : ""}
       -ml-1 p-2 hover:bg-gray-200 ${
         disabled || (disabledClick && selectedChat?._id !== cardItem.id)
           ? "cursor-not-allowed"
           : "cursor-pointer"
       } flex rounded-lg ${containerClass}`}
    >
      <div className="mr-[10px]">
        <div
          className={
            recents
              ? "w-[35px] h-[35px] relative"
              : "w-[50px] h-[50px] relative"
          }
        >
          {_avatar && _avatar?.path && _avatar?.name ? (
            <Avatar
              path={_avatar?.path}
              name={_avatar?.name}
              extraClass="shadow-sm"
              title={_name}
            />
          ) : (
            <NoAvatar
              colorClass={disabled ? "bg-gray-777 border-gray-777" : ""}
              displaytext={_name}
              elementClass="p-0"
              labelClass={`${recents ? "text-[10px]" : "text-[17px]"}`}
            />
          )}
          {cardItem.user?.roles?.includes(RoleUser.AGENT) &&
          !cardItem.group.groupchat ? (
            <div
              title="Status"
              className={`flex absolute ${
                recents ? "w-[10px] h-[10px]" : "w-[14px] h-[14px]"
              } ${
                cardItem.user.status === UserStatus.ONLINE
                  ? "bg-green"
                  : "bg-red"
              } rounded-full bottom-0 right-1 cursor-pointer`}
            />
          ) : null}
        </div>
      </div>
      <div className="ml-1 flex relative flex-1 flex-col max-w-[80%]">
        <div className="flex relative items-center justify-between">
          {getTitle()}
          {!recents ? (
            getDateLabel()
          ) : (
            <div className="flex relative items-center">
              <CloseButton
                extraClass="text-[12px] border-0"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  dispatch(
                    removeFromRecents(
                      recentChats.filter((c: IChat) => c._id === cardItem.id)[0]
                    )
                  );
                }}
              />
            </div>
          )}
        </div>
        {/* CUSTOMERS */}
        {!recents &&
        !cardItem.group.groupchat &&
        typeof cardItem.user.customer !== "undefined" &&
        cardItem.user.customer.length > 0 ? (
          <div
            className={`${
              recents ? "pt-[3px]" : ""
            } pb-[5px] flex relative items-center`}
          >
            <i
              className={`${recents ? "text-[10px]" : "text-[12px]"}  ${
                disabled ? "text-gray-text" : "text-gray-333"
              }  word-wrap-2 mr-[2px] font-semibold las la-building mt-[1px]`}
            />
            <h2
              className={`${recents ? "text-[10px]" : "text-[12px]"} ${
                disabled ? "text-gray-text" : "text-gray-333"
              }  word-wrap-2 w-full font-semibold`}
            >
              {cardItem.user.customer}
            </h2>
          </div>
        ) : null}
        {/* TEAMS */}
        {!recents &&
        !cardItem.user.team &&
        typeof cardItem.user.team !== "undefined" &&
        cardItem.user.team.length > 0 ? (
          <div
            className={`${
              recents ? "pt-[3px]" : ""
            } pb-[5px] flex relative items-center justify-between`}
          >
            <i
              className={`${recents ? "text-[10px]" : "text-[12px]"}  ${
                disabled ? "text-gray-text" : "text-gray-333"
              }  word-wrap-2 mr-[2px] font-semibold las la-user-friends mt-[1px]`}
            />
            <h2
              className={`${recents ? "text-[10px]" : "text-[12px]"} ${
                disabled ? "text-gray-text" : "text-gray-333"
              }  word-wrap-2 w-full font-semibold`}
            >
              {cardItem.user.team}
            </h2>
          </div>
        ) : null}
        {typeof cardItem.team?.name !== "undefined" &&
        cardItem.team.name.length > 0 ? (
          <div
            className={`${
              recents ? "pt-[3px]" : ""
            } pb-[5px] flex relative items-center justify-between`}
          >
            <i
              className={`${recents ? "text-[10px]" : "text-[12px]"}  ${
                disabled ? "text-gray-text" : "text-gray-333"
              }  word-wrap-2 mr-[2px] font-semibold las la-user-friends mt-[1px]`}
            />
            <h2
              className={`${recents ? "text-[10px]" : "text-[12px]"} ${
                disabled ? "text-gray-text" : "text-gray-333"
              }  word-wrap-2 w-full font-semibold`}
            >
              {cardItem.team.name}
            </h2>
          </div>
        ) : null}
        {!disabled ? (
          <div
            className={`${
              recents ? "pt-[3px]" : ""
            } pb-[5px] flex relative items-center justify-between`}
          >
            <h2
              className={`${
                recents ? "text-[11px]" : "text-[13px]"
              } text-gray-333 word-wrap-2 w-full`}
            >
              {getLastMessage()}
            </h2>
            {cardItem.badge > 0 || !getIfChatIsRead() ? (
              <NotificationBadge
                badge={cardItem.badge}
                extraBadge={getIfChatIsRead()}
              />
            ) : null}
          </div>
        ) : null}
        {!recents ? (
          <TagArea>
            {cardItem.tags.type !== ChatType.INTERNAL ? (
              <Tag
                tagType={cardItem.tags.status ? "chatstatus" : undefined}
                status={
                  mineChats.find((_c) => _c._id === cardItem.id)?.status ||
                  cardItem.tags.status
                }
              />
            ) : null}
            {cardItem.tags.type !== ChatType.WHATSAPP &&
            cardItem.tags.type !== ChatType.BOTMAKER ? (
              <Tag
                tagType={cardItem.tags.type ? "chattype" : undefined}
                type={cardItem.tags.type}
              />
            ) : null}
            {typeof cardItem.tags.type !== "undefined" &&
            [ChatType.WHATSAPP, ChatType.BOTMAKER].includes(
              cardItem.tags.type
            ) &&
            typeof cardItem.tags.campaign !== "undefined" ? (
              <Tag
                tagType={cardItem.tags.campaign ? "campaign" : undefined}
                campaign={cardItem.tags.campaign}
              />
            ) : null}
            {cardItem.tags.type !== ChatType.INTERNAL &&
            !cardItem.tags.campaign ? (
              <Tag
                tagType={cardItem.tags.externalapp ? "externalapp" : undefined}
                externalapp={cardItem.tags.externalapp}
                type={cardItem.tags.type}
              />
            ) : null}
            {cardItem.tags.type !== ChatType.INTERNAL ? (
              <Tag
                tagType={cardItem.tags.transferred ? "transferred" : undefined}
                transferred={cardItem.tags.transferred}
              />
            ) : null}
            {cardItem.tags.type === ChatType.INTERNAL ? (
              <Tag
                tagType={cardItem.tags.group ? "groupchat" : undefined}
                groupchat={cardItem.tags.group}
              />
            ) : null}
            {cardItem.tags.type !== ChatType.INTERNAL ? (
              <Tag
                tagType={cardItem.tags.offline ? "offline" : undefined}
                offline={cardItem.tags.offline}
              />
            ) : null}
            {/* {typeof cardItem.tags.custom !== "undefined" &&
            cardItem.tags.custom.length > 0
              ? cardItem.tags.custom.map((_tag) => (
                  <Tag key={_tag._id} tagType="custom" customTag={_tag} />
                ))
              : null} */}
            {getCustomTags()}
          </TagArea>
        ) : (
          <h2 className="flex pt-[3px] text-[8px] text-gray-777 justify-end">
            {typeof cardItem.lastmessage.stamp !== "undefined"
              ? formatRelative(
                  parseISO(cardItem.lastmessage.stamp),
                  new Date(),
                  {
                    locale,
                  }
                )
              : ""}
          </h2>
        )}
      </div>
    </div>
  );
};

export default ChatCard;
