import useFormatText from "../../hooks/useFormatText";
import { twMerge } from "tailwind-merge";

const Section = ({
  id,
  widget,
  icon,
  title,
  description,
  component,
  fullarea,
  children,
  tableStyles,
}: {
  id?: string;
  widget?: boolean;
  fullarea?: boolean;
  icon: string;
  title: string;
  description: string;
  component: JSX.Element;
  children?: JSX.Element;
  tableStyles?: string;
}) => {
  return (
    <div id={id} className={`${!widget ? "mt-10" : "mt-0 w-full"} sm:mt-0`}>
      <div
        className={`grid xl:grid-cols-2 ${
          fullarea ? "xl:grid-cols-2" : "xl:grid-cols-3"
        }  md:gap-4`}
      >
        {!widget ? (
          <div className={`${fullarea ? "md:col-span-2" : "md:col-span-1"}`}>
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900 flex items-center">
                <i className={`${icon} mr-2 text-lg`} />
                <p>{title}</p>
              </h3>
              <p
                className="mt-1 text-sm text-gray-600"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: useFormatText(description),
                }}
              />
              {children && children}
            </div>
          </div>
        ) : null}
        <div
          className={twMerge(
            !widget ? "mt-5 shadow" : "",
            "md:col-span-2 md:mt-0 sm:rounded-md",
            tableStyles
          )}
        >
          <div
            className={twMerge(
              !widget ? "px-4 py-5" : "px-2 py-3",
              "bg-white sm:p-6 h-full"
            )}
          >
            <div className="mx-4">{component}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Section.defaultProps = {
  id: "",
  widget: false,
  fullarea: false,
};

export default Section;
