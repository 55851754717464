/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LinkButton from "../../../../shared/components/Buttons/LinkButton.component";
import ActionButtonsModal from "../../../../shared/components/Modals/ActionButtonsModal.component";
import { ddOptions } from "../../../../shared/models/interfaces/dropdownOptions.interface";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import {
  ITeam,
  selectTeams,
  teamsChatsAssignment,
} from "../../../../slices/teams.slice";
import {
  ChatStatus,
  ChatType,
  chatIsTransfering,
  clearNotifications,
  closeChat,
  removeFromMine,
  reselectChat,
  transferChat,
  changeChat,
  IChat,
  updateChat,
  updateReadChat,
} from "../../../../slices/chats.slice";
import { IUser, RoleUser, UserStatus } from "../../../../slices/users.slice";
import {
  INewMessage,
  INewMessageBotmaker,
  INewMessageWpp,
  MessageType,
  addMessage,
  createMessage,
  readAll,
  sendMessageBotmaker,
  sendMessageWpp,
  sendsocket,
} from "../../../../slices/messages.slice";
import { IAcceptedFiles } from "../../../../shared/models/interfaces/acceptedfile.interface";
import FilesArea from "../../../../shared/components/Files/FilesArea.component";
import { Files } from "../../../../shared/models/interfaces/file.interface";
import {
  authService,
  botmakerService,
  messagesService,
  settingsService,
  teamsService,
  whatsappService,
} from "../../../../services";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import { Header } from "../../../../shared/components/Sidebars";
import ChatDetailsCard from "./Details/ChatDetailsCard.component";
import customToast from "../../../../shared/utils/customToast";
import { IToastType } from "../../../../shared/models/types/Toast.type";
import Dropdown from "../../../../shared/components/Dropdown.component";
import { ISettings } from "../../../../slices/settings.slice";
import useUserIsAdmin from "../../../../hooks/useUserIsAdmin";
import Toggle from "../../../../shared/components/Toggle.component";
import { storeUserChatPreferences } from "../../../../slices/preferences.slice";
import { getChatReadArray } from "../utils";

const ChatTransfer = ({
  disable,
  setVisible,
  setDisable,
  setThumbs,
  chatContact,
  setLastUrl,
  goBack,
}: {
  disable?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
  chatContact: IUser | null | undefined;
  setLastUrl: React.Dispatch<React.SetStateAction<string>>;
  goBack: () => void;
  setThumbs?: React.Dispatch<React.SetStateAction<IAcceptedFiles>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedChat, isTransfering } = useAppSelector(
    (state) => state.chats
  );
  const { teams } = useAppSelector((state) => state.teams);
  const { user } = useAppSelector((state) => state.auth);

  const [selectedDdteam, setSelectedDdteam] = useState<ITeam>();
  const [selectedDduser, setSelectedDduser] = useState<{
    _id: string;
    name: string;
  }>({ _id: "", name: "" });
  let to: string;
  const [ddteams, setDdteams] = useState<ddOptions[]>([]);
  const [ddusers, setDdusers] = useState<ddOptions[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [reason, setReason] = useState("");
  const [showFiles, setShowFiles] = useState(false);
  const [offlineTeams, setOfflineTeams] = useState(false);
  const [offlineUsers, setOfflineUsers] = useState(false);
  const [files, setFiles] = useState<IAcceptedFiles>([]);

  const [focusId, setFocusId] = useState<string>("");

  const toggleUsers = () => {
    setShowUsers((prevState) => !prevState);
    setSelectedDduser({ _id: "", name: "" });
  };

  const toggleFiles = () => {
    setShowFiles((prevState) => !prevState);
    setFiles([]);
  };

  const getContent = () => {
    if (user?.name && selectedDduser?.name && selectedDdteam?.name) {
      return (
        t("chat-transfer.internal-message.agitalks-user.part1") +
        user.name +
        t("chat-transfer.internal-message.agitalks-user.part2") +
        selectedDduser.name +
        t("chat-transfer.internal-message.agitalks-user.part3") +
        selectedDdteam.name +
        t("chat-transfer.internal-message.agitalks-user.part4")
      );
    }

    if (user?.name && selectedDdteam?.name) {
      return (
        t("chat-transfer.internal-message.agitalks-onlyteam.part1") +
        user.name +
        t("chat-transfer.internal-message.agitalks-onlyteam.part2") +
        selectedDdteam.name +
        t("chat-transfer.internal-message.agitalks-onlyteam.part3")
      );
    }
    return "";
  };

  const getContentWpp = () => {
    if (user?.name && selectedDduser?.name && selectedDdteam?.name) {
      return (
        t("chat-transfer.internal-message.whatsapp-user.part1") +
        user.name +
        t("chat-transfer.internal-message.whatsapp-user.part2") +
        selectedDduser.name +
        t("chat-transfer.internal-message.whatsapp-user.part3") +
        selectedDdteam.name +
        t("chat-transfer.internal-message.whatsapp-user.part4")
      );
    }

    if (user?.name && selectedDdteam?.name) {
      return (
        t("chat-transfer.internal-message.whatsapp-onlyteam.part1") +
        user.name +
        t("chat-transfer.internal-message.whatsapp-onlyteam.part2") +
        selectedDdteam.name +
        t("chat-transfer.internal-message.whatsapp-onlyteam.part3")
      );
    }
    return "";
  };

  const transferAction = async (actionSettings: ISettings) => {
    try {
      if (!selectedChat?._id || !chatContact?._id) return;

      const _updateUsers = [chatContact._id];
      if (
        chatContact?._id &&
        typeof selectedDduser._id !== "undefined" &&
        selectedDduser._id.length > 0
      ) {
        to = selectedDduser._id;
        _updateUsers.push(selectedDduser._id);
      }
      const { payload } = await dispatch(
        transferChat({
          _id: selectedChat._id,
          team:
            typeof selectedDdteam?._id !== "undefined"
              ? selectedDdteam._id
              : "",
          to:
            typeof selectedDduser._id !== "undefined" &&
            selectedDduser._id.length > 0
              ? selectedDduser._id
              : undefined,
          users: _updateUsers,
          auto:
            typeof selectedDduser._id === "undefined" ||
            selectedDduser._id.length === 0
              ? false
              : undefined,
          status:
            typeof to !== "undefined" &&
            to.length > 0 &&
            typeof selectedChat.status !== "undefined"
              ? selectedChat?.status
              : ChatStatus.WAIT,
        })
      );

      // const _chat = {
      //   _id: selectedChat?._id,
      //   team: selectedDdteam?._id,
      //   status:
      //     typeof to !== "undefined" && to.length > 0
      //       ? selectedChat?.status
      //       : ChatStatus.WAIT,
      //   users: _updateUsers,
      //   transferred: true,
      // };

      // const { payload } = await dispatch(updateChat(_chat));

      const getMessageError = (_files: string) => {
        if (_files.toLowerCase().includes("unsupported")) {
          return t("toast.error.files.unsupported");
        }
        if (_files.toLowerCase().includes("too large"))
          return t("toast.error.files.too-large");
        return t("toast.error.files.upload");
      };

      // if (payload) {
      if (typeof payload !== "undefined" && payload !== null) {
        let _files: Files | string | null = [];
        let fileIds: string[] = [];
        if (files.length > 0) _files = await authService.uploadFile(files);
        if (typeof _files === "string") {
          customToast({
            type: IToastType.ERROR,
            message: getMessageError(_files),
          });
          return;
        }

        if (_files && _files !== null && _files?.length > 0) {
          fileIds = _files.map((file) => file?._id || "");
        }
        const actionTeam = await teamsService.team({
          _id: selectedDdteam?._id || "",
        });
        const newMessageContact: INewMessage = {
          chat: {
            ...selectedChat,
            team: actionTeam,
            status: payload.status,
            transferred: payload.transferred,
            users: payload.users,
          },
          content: getContent(),
          files: [],
          parent: null,
          internal: true,
          read: true,
          origin: user?._id,
          type: MessageType.CONTACT,
        };

        const newMessageUser: INewMessage = {
          chat: {
            ...selectedChat,
            team: actionTeam,
            status: payload.status,
            transferred: payload.transferred,
            users: payload.users,
          },
          content:
            reason.length > 0
              ? `${getContent()}\n\n${t(
                  "chat-transfer.internal-message.reason"
                )}\n${reason}`
              : getContent(),
          files: fileIds || [],
          parent: null,
          internal: true,
          read: false,
          origin: user?._id,
          type: MessageType.USER,
        };

        const actionContact = await dispatch(createMessage(newMessageContact));

        if (actionContact.payload) {
          if (actionContact && actionContact.payload) {
            const defaultMessageContent = {
              message_id: actionContact?.payload?._id,
              to: chatContact?.phone || "",
              type: "text",
              recipient_type: "individual",
            };
            const newMessage =
              actionContact.payload?.chat?.type === ChatType.WHATSAPP
                ? ({
                    ...defaultMessageContent,
                    externalapp:
                      actionContact?.payload?.chat?.externalapp?._id ||
                      actionContact?.payload?.chat?.externalapp ||
                      undefined,
                    messaging_product: "whatsapp",
                    text: {
                      preview_url: true,
                      body: getContentWpp(),
                    },
                  } as INewMessageWpp)
                : actionContact.payload?.chat?.type === ChatType.BOTMAKER
                ? ({
                    ...defaultMessageContent,
                    messaging_product: "botmaker",
                    message: {
                      text: getContentWpp(),
                    },
                    externalapp: actionContact?.payload?.chat?.externalapp,
                    externalcode: actionContact?.payload?.chat?.externalcode,
                    externalcodephone:
                      actionContact?.payload?.chat?.externalcodephone,
                  } as INewMessageBotmaker)
                : {};

            const sendMessage =
              actionContact.payload?.chat?.type === ChatType.WHATSAPP
                ? sendMessageWpp(newMessage as INewMessageWpp)
                : actionContact.payload?.chat?.type === ChatType.BOTMAKER
                ? sendMessageBotmaker(newMessage as INewMessageBotmaker)
                : null;

            if (typeof sendMessage !== "undefined" && sendMessage != null) {
              await dispatch(sendMessage);
            }
          }
          const action = await dispatch(createMessage(newMessageUser));
          // if (action.payload) {
          if (
            typeof action.payload !== "undefined" &&
            action.payload !== null
          ) {
            dispatch(removeFromMine(selectedChat));
            dispatch(
              reselectChat({
                prevStatus: ChatStatus.ACTIVE,
                chat: {
                  ...selectedChat,
                  status:
                    action.payload.chat.users.length === 1
                      ? ChatStatus.WAIT
                      : selectedChat.status,
                  team: actionTeam,
                  users: action.payload.chat.users,
                  transferred: action.payload.chat.transferred,
                  updated_at: action.payload.chat.updated_at,
                  updated_by: action.payload.chat.updated_by,
                },
              })
            );
            dispatch(
              changeChat({
                prevStatus: ChatStatus.ACTIVE,
                chat: {
                  ...selectedChat,
                  status:
                    action?.payload?.chat?.users?.length === 1
                      ? ChatStatus.WAIT
                      : selectedChat.status,
                  team: actionTeam,
                  users: action.payload.chat.users,
                  transferred: action.payload.chat.transferred,
                  updated_at: action.payload.chat.updated_at,
                  updated_by: action.payload.chat.updated_by,
                },
              })
            );
            if (action?.payload?.chat?.users?.length > 1) {
              const _agent = action.payload.chat.users?.find(
                (_user: IUser) => !_user?.roles?.includes(RoleUser.CONTACT)
              );
              if (typeof _agent !== "undefined") {
                const sendInternalMessage = async (
                  chat: IChat,
                  agent: IUser
                ) => {
                  const newMessage: INewMessage = {
                    chat,
                    content: `Agente <b>${agent?.name}</b> iniciou a conversa.`,
                    files: [],
                    parent: null,
                    internal: true,
                    read: true,
                    origin: agent._id,
                  };
                  const _payload = await messagesService.create(newMessage);
                  if (_payload !== null) {
                    dispatch(addMessage(_payload));
                    dispatch(
                      sendsocket({
                        chat: action.payload.chat,
                        message: _payload,
                      })
                    );

                    const defaultNewMessage = {
                      message_id: _payload?._id,
                      to:
                        `${
                          chat?.users?.find(
                            (_user: IUser) => _user._id !== agent?._id
                          )?.phone
                        }` || "",
                      type: "text",
                      recipient_type: "individual",
                    };
                    const startChatMsg = `Agente *${agent?.name}* iniciou a conversa.`;
                    const newMessageContent =
                      _payload.chat?.type === ChatType.WHATSAPP
                        ? ({
                            ...defaultNewMessage,
                            externalapp:
                              _payload.chat?.externalapp?._id ||
                              _payload.chat?.externalapp ||
                              undefined,
                            messaging_product: "whatsapp",
                            text: {
                              preview_url: true,
                              body: startChatMsg,
                            },
                          } as INewMessageWpp)
                        : _payload.chat?.type === ChatType.BOTMAKER
                        ? ({
                            ...defaultNewMessage,
                            externalapp:
                              _payload.chat?.externalapp || undefined,
                            externalcode: _payload.chat?.externalcode,
                            externalcodephone: _payload.chat?.externalcodephone,
                            messaging_product: "botmaker",
                            to: "",
                            message: {
                              text: startChatMsg,
                            },
                          } as INewMessageBotmaker)
                        : null;

                    switch (_payload?.chat?.type) {
                      case ChatType.WHATSAPP:
                        await whatsappService.createWpp(
                          newMessageContent as INewMessageWpp
                        );
                        break;
                      case ChatType.BOTMAKER:
                        await botmakerService.createBotmaker(
                          newMessageContent as INewMessageBotmaker
                        );
                        break;
                      default:
                        break;
                    }
                  }
                };
                await sendInternalMessage(action.payload.chat, _agent);
              }
            } else {
              const getContentTransferred = () => {
                if (
                  actionSettings === null ||
                  typeof actionSettings === "undefined" ||
                  typeof actionSettings?.defaultbot === "undefined" ||
                  typeof actionSettings?.defaultbot.welcomemsg === "undefined"
                )
                  return `Você está na fila de espera, aguarde um momento para ser atendido.`;
                if (
                  actionSettings !== null &&
                  typeof actionSettings !== "undefined" &&
                  typeof actionSettings?.defaultbot?.welcomemsg === "string" &&
                  actionSettings?.defaultbot?.welcomemsg.length > 0
                )
                  return actionSettings?.defaultbot?.welcomemsg;
                return `Você está na fila de espera, aguarde um momento para ser atendido.`;
              };

              const getOrigin = () => {
                if (
                  actionSettings === null ||
                  typeof actionSettings === "undefined" ||
                  actionSettings?.defaultagent === null ||
                  typeof actionSettings?.defaultagent === "undefined"
                )
                  return undefined;
                if (
                  actionSettings !== null &&
                  typeof actionSettings !== "undefined" &&
                  actionSettings?.defaultagent !== null &&
                  typeof actionSettings?.defaultagent === "string"
                )
                  return actionSettings?.defaultagent;
                if (
                  actionSettings !== null &&
                  typeof actionSettings !== "undefined" &&
                  typeof actionSettings?.defaultagent !== "undefined" &&
                  actionSettings?.defaultagent !== null &&
                  typeof actionSettings?.defaultagent?._id !== "undefined"
                )
                  return actionSettings?.defaultagent?._id;
                return undefined;
              };

              const sendInternalMessage = async (chat: IChat) => {
                const newMessage: INewMessage = {
                  chat,
                  content: getContentTransferred(),
                  files: [],
                  parent: null,
                  internal: true,
                  read: true,
                  origin: getOrigin(),
                  type: MessageType.CONTACT,
                };
                const _payload = await messagesService.create(newMessage);
                if (_payload !== null) {
                  dispatch(addMessage(_payload));
                  dispatch(
                    sendsocket({
                      chat,
                      message: _payload,
                    })
                  );
                  if (chat?.type === ChatType.WHATSAPP) {
                    const newMessageWpp: INewMessageWpp = {
                      externalapp:
                        _payload?.chat?.externalapp?._id ||
                        _payload?.chat?.externalapp ||
                        undefined,
                      message_id: _payload?._id,
                      messaging_product: "whatsapp",
                      to: chat?.users ? chat?.users[0].phone || "" : "",
                      type: "text",
                      recipient_type: "individual",
                      text: {
                        preview_url: true,
                        body: getContentTransferred(),
                      },
                    };
                    await whatsappService.createWpp(newMessageWpp);
                  }
                }
              };
              if (action.payload.chat.status === ChatStatus.WAIT) {
                await sendInternalMessage(action.payload.chat);
              }
            }
          }
        }
      }
    } catch (error) {
      // console.log("e", error);
    }
  };

  const submitAction = async () => {
    dispatch(chatIsTransfering(true));
    const actionSettings = await settingsService.settings();
    if (typeof actionSettings === "undefined" || actionSettings === null)
      return;
    if (!selectedChat) return;
    if (setDisable) setDisable(true);
    if (setThumbs) setThumbs([]);
    if (user?.roles?.includes(RoleUser.AGENT) || isAdmin) {
      await dispatch(readAll(selectedChat._id || ""));
      if (
        typeof selectedChat?._id !== "undefined" &&
        typeof user?._id !== "undefined"
      ) {
        const _readResults = getChatReadArray({
          chat: selectedChat,
          userId: user?._id,
          read: true,
        });
        await dispatch(
          updateReadChat({
            _id: selectedChat._id,
            read: _readResults,
          })
        );
        dispatch(
          changeChat({
            chat: {
              _id: selectedChat?._id,
              read: _readResults,
            },
          })
        );
        dispatch(
          storeUserChatPreferences({
            user: user?._id,
            _id: selectedChat?._id,
            chat: selectedChat || undefined,
            read: true,
          })
        );
      }
      dispatch(clearNotifications("mine"));
    }

    await transferAction(actionSettings);
    dispatch(chatIsTransfering(false));
    if (setDisable) setDisable(false);
    if (setVisible) setVisible(false);
    if (selectedChat?._id) {
      await dispatch(teamsChatsAssignment());
      dispatch(closeChat());
    }
    // CLOSE MODAL
    navigate("/chat");
  };

  return (
    <div className="flex-container p-3">
      <div className="header">
        {/* HEADER PAGE */}
        <Header
          icon="las la-share"
          title={t("chat-transfer.header")}
          setVisible={setVisible}
        />
      </div>
      <div id="sidemenu" className="px-4 content overflow-y-scroll">
        {isTransfering ? (
          <Form>
            <LoadingDots medium className="flex justify-center items-center" />
          </Form>
        ) : (
          <Form>
            <ChatDetailsCard
              chatContact={chatContact}
              setLastUrl={setLastUrl}
            />
            <Toggle
              containerClass="flex w-full -mt-1 pt-4"
              defaultChecked={offlineUsers}
              mainClass=""
              onChange={(_, { checked }) => {
                setOfflineTeams(checked || false);
                setDdusers([]);
                setSelectedDduser({ _id: "", name: "" });
              }}
              labelClass="ml-4 mb-5"
              label={
                offlineTeams
                  ? "Esconder equipes fora de horário de atendimento"
                  : "Exibir equipes fora de horário de atendimento"
              }
            />
            <Dropdown
              id="dropdown-transfer-teams"
              loading={isLoading && focusId === "dropdown-transfer-teams"}
              className=""
              required
              label={t("form.label.teams") || "form.label.teams"}
              fluid
              search
              selection
              defaultValue={selectedDdteam?._id}
              placeholder={
                t("form.placeholder.teams") || "form.placeholder.teams"
              }
              options={ddteams}
              onFocus={() => {
                setFocusId("dropdown-transfer-teams");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              // TODO
              // onSearchChange={async (e: DropdownSearchInputProps) => {
              //   setIsLoading(true);
              //   const payload = await teamsService.search({
              //     skip: 0,
              //     limit: 10,
              //     filter: e.target.value,
              //     deleted: false,
              //     withChatusers: true,
              //     transferred: true,
              //   });
              //   setIsLoading(false);
              //   dispatch(selectTeams(payload.results));
              //   const _optionsInstance: ddOptions[] = [];
              //   payload.results.map((_team: ITeam) => {
              //     if (
              //       _optionsInstance.filter(
              //         (_opTeam) => _opTeam.key === _team._id
              //       )[0]
              //     ) {
              //       return false;
              //     }
              //     _optionsInstance.push({
              //       key: _team._id,
              //       text: _team.name,
              //       value: _team._id,
              //     });
              //     return true;
              //   });
              //   setDdteams(_optionsInstance);
              // }}
              onOpen={async () => {
                setIsLoading(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 200,
                  filter: "",
                  deleted: false,
                  withChatusers: true,
                  transferred: !offlineTeams,
                });
                setIsLoading(false);
                dispatch(selectTeams(payload.results));
                const _optionsTeams: ddOptions[] = [];
                payload.results.map((_team: ITeam) =>
                  _optionsTeams.push({
                    key: _team._id,
                    text: _team.name,
                    value: _team._id,
                  })
                );
                setDdteams(_optionsTeams);
              }}
              onChange={(e, { value }) => {
                setDdusers([]);
                setSelectedDduser({ _id: "", name: "" });
                const _value = value as string;
                setSelectedDdteam(
                  teams.filter((_team) => _team._id === _value)[0]
                );
              }}
            />
            {selectedDdteam ? (
              <>
                <div className="flex justify-end">
                  <LinkButton
                    label={
                      !showUsers
                        ? t("linkbutton.label.show-users")
                        : t("linkbutton.label.hide-users")
                    }
                    onClick={toggleUsers}
                    extraClass="mb-2 text-[12px] font-semibold"
                  />
                </div>
                {showUsers ? (
                  <>
                    <Toggle
                      containerClass="flex w-full -mt-1"
                      defaultChecked={offlineUsers}
                      mainClass=""
                      onChange={(_, { checked }) => {
                        setOfflineUsers(checked || false);
                        setDdusers([]);
                        setSelectedDduser({ _id: "", name: "" });
                      }}
                      labelClass="ml-4 mb-5"
                      label={
                        offlineUsers
                          ? "Esconder agentes offline na lista"
                          : "Exibir agentes offline na lista"
                      }
                    />

                    <Dropdown
                      id="dropdown-transfer-users"
                      loading={
                        isLoading && focusId === "dropdown-transfer-users"
                      }
                      className="mt-4"
                      label={t("form.label.users") || "form.label.users"}
                      required={showUsers}
                      fluid
                      search
                      selection
                      options={ddusers}
                      defaultValue={selectedDduser._id}
                      placeholder={
                        t("form.placeholder.users") || "form.placeholder.users"
                      }
                      onOpen={async () => {
                        if (typeof selectedDdteam._id !== "undefined") {
                          setIsLoading(true);
                          const payload =
                            await teamsService.postChatusersByTeamId({
                              _id: selectedDdteam._id,
                              offline: offlineUsers,
                            });
                          setIsLoading(false);
                          const _optionsUsers: ddOptions[] = [];
                          if (
                            typeof payload !== "undefined" &&
                            payload?.length > 0
                          ) {
                            payload
                              ?.sort(
                                (
                                  a: { _id: string; name: string },
                                  b: { _id: string; name: string }
                                ) => a.name.localeCompare(b.name)
                              )
                              ?.map(
                                (_user: {
                                  _id: string;
                                  name: string;
                                  email: string;
                                  status: UserStatus;
                                }) =>
                                  _optionsUsers.push({
                                    key: _user._id,
                                    text: _user.name,
                                    value: _user._id,
                                    content: (
                                      <div className="relative flex items-center gap-2">
                                        <div
                                          className={`flex w-[13px] h-[13px] ${
                                            _user.status === UserStatus.ONLINE
                                              ? "bg-green"
                                              : "bg-red"
                                          } rounded-full mr-2 cursor-pointer`}
                                        />
                                        <div className="flex items-center">
                                          <h1 className="mr-2">
                                            {_user?.name}
                                          </h1>
                                          {typeof _user?.email !==
                                          "undefined" ? (
                                            <h1 className="text-gray-text text-[12px]">
                                              ({_user?.email})
                                            </h1>
                                          ) : null}
                                        </div>
                                      </div>
                                    ),
                                  })
                              );
                          }
                          setDdusers(_optionsUsers);
                        }
                      }}
                      onFocus={() => {
                        setFocusId("dropdown-transfer-users");
                      }}
                      onBlur={() => {
                        setFocusId("");
                      }}
                      onChange={(e, { value }) => {
                        const _value = value as string;
                        const _user = ddusers?.filter(
                          (_u) => _u.key === _value
                        )[0];
                        setSelectedDduser({
                          _id: _value,
                          name: _user?.text || "",
                        });
                      }}
                    />
                  </>
                ) : null}
                <Form.TextArea
                  style={{ resize: "none" }}
                  label={t("form.label.reason")}
                  placeholder={t("form.label.help")}
                  maxLength={1024}
                  type="text"
                  defaultValue={reason}
                  required
                  onChange={(e, { value }) => {
                    const _value = value as string;
                    setReason(_value);
                  }}
                />
                <div className="flex justify-end">
                  <LinkButton
                    label={
                      !showFiles
                        ? t("linkbutton.label.show-files")
                        : t("linkbutton.label.hide-files")
                    }
                    onClick={toggleFiles}
                    extraClass="mb-2 text-[12px] font-semibold"
                  />
                </div>
                {showFiles &&
                selectedChat &&
                typeof selectedChat._id !== "undefined" ? (
                  <FilesArea
                    chat={selectedChat}
                    files={files}
                    setFiles={setFiles}
                  />
                ) : null}
              </>
            ) : null}
          </Form>
        )}
      </div>
      <div className="footer mx-4">
        {offlineTeams || offlineUsers ? (
          <div className="disclaimer-offline">
            <div className="disclaimer-container">
              <i className="las la-info-circle disclaimer-icon" />
              <div className="disclaimer-content">
                <span>Possível demora para atendimento</span>
                <p>
                  Este chat será transferido para um agente offline, o que pode
                  resultar em um tempo de resposta um pouco maior.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <ActionButtonsModal
          sidebar
          disabled={
            disable ||
            reason.length === 0 ||
            typeof selectedDdteam?._id === "undefined" ||
            (typeof selectedDdteam?._id !== "undefined" &&
              showUsers &&
              selectedDduser._id.length === 0)
          }
          cancelAction={goBack}
          submitIcon="las la-share"
          submitLabel={t("formbutton.label.transfer") || ""}
          submitAction={submitAction}
        />
      </div>
    </div>
  );
};

ChatTransfer.defaultProps = {
  disable: false,
  setVisible: undefined,
  setDisable: undefined,
  setThumbs: undefined,
};

export default ChatTransfer;
