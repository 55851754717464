import { twJoin } from "tailwind-merge";

interface CustomToggleProps {
  checkedColor?: string;
  uncheckedColor?: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  classname?: string;
}

const CustomToggle: React.FC<CustomToggleProps> = ({
  checkedColor = "peer-checked:bg-green",
  uncheckedColor = "bg-red",
  checked,
  onChange,
  disabled = false,
  classname,
}) => (
  <label
    className={twJoin("!relative inline-block leading-none h-5", classname)}
  >
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="
        w-8 h-0 cursor-pointer
        focus:outline-0 dark:focus:outline-0
        border-0 dark:border-0
        focus:ring-offset-transparent dark:focus:ring-offset-transparent
        focus:ring-transparent dark:focus:ring-transparent
        focus-within:ring-0 dark:focus-within:ring-0
        focus:shadow-none dark:focus:shadow-none
        peer
      "
    />
    <span
      className={twJoin(
        "absolute top-0 left-0 block w-8 h-[18px] rounded-full transition duration-300 disabled:bg-opacity-40 peer-disabled:cursor-not-allowed dark:bg-gray-600",
        uncheckedColor,
        checkedColor
      )}
    />
    <span
      className="absolute top-0 left-0 block w-4 h-4 mt-0.5 ml-0.5 bg-white 
      dark:bg-gray-50 rounded-full shadow-md transition-transform duration-300 
        peer-checked:translate-x-3 translate-x-0 peer-disabled:bg-opacity-75"
    />
  </label>
);

export default CustomToggle;
