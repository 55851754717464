import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import SearchBox from "../../../shared/components/SearchBox.component";
import {
  closeChat,
  IChat,
  openChat,
  QuickFiltersID,
  removeFromRecents,
} from "../../../slices/chats.slice";
import { UserType } from "../../../slices/users.slice";
import ChatCard from "./ChatCard.component";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import useOnScreen from "../../../hooks/useOnScreen";
import Button from "../../../shared/components/Buttons/Button.component";
import HistorySelectorContact from "./HistorySelectorContact.component";
import allowedToOpenChat from "../../../shared/utils/allowedToOpenChat";
import { chatsService } from "../../../services";
import useLGPD from "../../../hooks/useLGPD";
import TipTagsComponent from "./TipTags.component";
import ChatSortComponent from "./ChatSort.component";
import { resetWaitingMessages } from "../../../slices/messages.slice";
import removeDuplicates from "../../../shared/utils/removeDuplicates";

const ChatHistory = ({
  setShowFilter,
  widgetType,
  setShow,
}: {
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType?: UserType;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [skipHistory, setSkipHistory] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreHistory, setHasMoreHistory] = useState(false);

  const { user } = useAppSelector((state) => state.auth);

  const [selectedTips, setSelectedTips] = useState<string[]>(
    user?.chatfilters?.tips ?? []
  );
  const [selectedSort, setSelectedSort] = useState<number>(
    user?.chatfilters?.sort?.history ?? 1
  );

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const { showCode } = useLGPD();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedChat, selectedHistoryContact } = useAppSelector(
    (state) => state.chats
  );

  const [listRef] = useOnScreen({
    hasMore: hasMoreHistory,
    isLoading,
    setSkip: setSkipHistory,
  });
  const [renderDataHistory, setRenderDataHistory] = useState<IChat[]>([]);

  useEffect(() => {
    if (skipHistory > 0) {
      setSkipHistory(0);
      setHasMoreHistory(true);
    }
  }, [selectedSort, selectedTips]);

  useEffect(() => {
    setSelectedSort(user?.chatfilters?.sort?.history ?? 1);
    setSelectedTips(user?.chatfilters?.tips ?? []);
    setSearchKeyword("");
    setShowFilter(false);
    setIsLoading(true);
    setSkipHistory(0);
  }, [selectedHistoryContact?.key]);

  useEffect(() => {
    (async () => {
      if (typeof selectedHistoryContact?.key === "undefined") return;
      const payload = await chatsService.advancedSearch({
        skip: skipHistory,
        limit: 10,
        history: true,
        code: showCode,
        sort: selectedSort,
        filter:
          typeof selectedHistoryContact?.key !== "undefined"
            ? {
                content: searchKeyword,
                users: [selectedHistoryContact?.key],
                period:
                  selectedTips.length > 0
                    ? selectedTips.filter((_tip) =>
                        [
                          QuickFiltersID.TODAY,
                          QuickFiltersID.YESTERDAY,
                          QuickFiltersID.THISWEEK,
                        ].includes(_tip as QuickFiltersID)
                      )[0]
                    : undefined,
                read:
                  selectedTips.length > 0 &&
                  selectedTips.includes(QuickFiltersID.NOTREAD)
                    ? "false"
                    : undefined,
              }
            : undefined,
      });
      const _results = payload?.results?.filter(
        (_chat: IChat) => _chat?.lastmessage
      );
      setIsLoading(false);
      if (skipHistory + 10 >= payload?.count) {
        setHasMoreHistory(false);
      } else {
        setHasMoreHistory(true);
      }
      if (skipHistory > 0) {
        setRenderDataHistory(
          removeDuplicates(renderDataHistory.concat(_results), "_id")
        );
      } else {
        setRenderDataHistory(removeDuplicates(_results, "_id"));
      }
    })();
  }, [
    skipHistory,
    searchKeyword,
    selectedHistoryContact?.key,
    selectedSort,
    selectedTips,
  ]);

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  });

  const handleClick = async (chat: IChat) => {
    if (allowedToOpenChat(chat, user)) {
      const users = chat?.users || null;
      if (users) {
        dispatch(removeFromRecents(chat));
        if (selectedChat?._id && widgetType !== UserType.CONTACT) {
          dispatch(closeChat());
        }
        navigate(`/chat/${chat._id}`);
        setShow(false);
        dispatch(resetWaitingMessages());
        dispatch(openChat(chat));
      }
    }
  };

  interface noChatsObject {
    [key: string]: string;
  }

  const noChats: noChatsObject = {
    history: "O histórico não possui conversas.",
    research: "Nenhuma conversa encontrada.",
  };

  const emptyList = (content: noChatsObject[string]) => {
    return (
      <div className="flex relative mt-[20px]">
        <h2 className="my-0 mx-auto mt-[50px]">{content}</h2>
      </div>
    );
  };

  const listBehavior = ({ list }: { list: IChat[] }) => {
    return (
      <ul
        className={`overflow-x-hidden overflow-y-scroll flex-1 relative pl-4 pr-2 ${
          widgetType === UserType.AGENT ? "widget" : ""
        }`}
      >
        <li>
          <div ref={scrollRef} />
        </li>
        {list.map((chat, index) => {
          if (user) {
            return (
              <li
                ref={index === list.length - 1 ? listRef : null}
                aria-hidden
                key={chat?._id}
                onClick={() => {
                  if (chat._id === selectedChat?._id) return;
                  if (allowedToOpenChat(chat, user)) handleClick(chat);
                }}
              >
                <ChatCard
                  chat={chat}
                  disabled={!allowedToOpenChat(chat, user)}
                  historyMode
                />
                <div className="w-full border-t border-gray-ddd my-1 -ml-1 mr-1" />
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const getList = () => {
    if (renderDataHistory.length > 0) {
      return listBehavior({
        list: renderDataHistory,
      });
    }
    return emptyList(
      searchKeyword.length > 0 || selectedTips.length > 0
        ? noChats.research
        : noChats.history
    );
  };

  const getLoadingCondition = () => isLoading && renderDataHistory.length === 0;

  const getListComponent = () => {
    return (
      <>
        <SearchBox
          extraClass="p-4"
          placeholder="Pesquisar no histórico..."
          _escFunction={() => {
            setSkipHistory(0);
            setSearchKeyword("");
          }}
          keyword={searchKeyword}
          listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSkipHistory(0);
            setSearchKeyword(e.target.value);
          }}
        />

        <div className="px-4 pb-2 w-full flex justify-between">
          <TipTagsComponent
            type="history"
            // disabled={renderDataHistory.length === 0}
            disabled={false}
            selectedTips={selectedTips}
            setSelectedTips={setSelectedTips}
          />
          <ChatSortComponent
            disabled={renderDataHistory.length === 0}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            toggleList="history"
          />
        </div>

        {getLoadingCondition() ? (
          <LoadingDots className="flex flex-1 justify-center items-center" />
        ) : (
          <>
            {getList()}
            <Button
              minWidth
              disabled={false}
              extraClass="absolute bottom-0 right-0 m-[16px] xl:w-1/4 w-1/3 min-w-fit"
              label="Nova conversa"
              onClick={() => setShow(true)}
              icon="las la-plus"
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {selectedHistoryContact !== null &&
      typeof selectedHistoryContact?.key !== "undefined" &&
      selectedHistoryContact?.key.length > 0 ? (
        getListComponent()
      ) : (
        <HistorySelectorContact />
      )}
      <Button
        minWidth
        disabled={false}
        extraClass="absolute bottom-0 right-0 m-[16px] xl:w-1/4 w-1/3 min-w-fit"
        label="Nova conversa"
        onClick={() => setShow(true)}
        icon="las la-plus"
      />
    </>
  );
};

ChatHistory.defaultProps = {
  widgetType: UserType.NONE,
};

export default ChatHistory;
