import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customersService, usersService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";

export interface ICustomer {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  name?: string;
  active?: boolean;
  fullname?: string;
  code?: string;
  reference?: string;
  externalcode?: string;
  contacts?: IUser[];
}

export type Customers = ICustomer[];

export interface INewCustomer {
  name: string;
}
interface CustomersState extends AsyncState {
  isLoadingCustomers: boolean;
  isLoadingDropdownCustomers: boolean;
  isCreatingCustomers: boolean;
  isRemoving: boolean;
  customers: Customers;
  totalCustomers: number;
  selectedCustomer: ICustomer | null;
}

const initialState: CustomersState = {
  customers: [],
  totalCustomers: 0,
  selectedCustomer: null,
  isRemoving: false,
  isLoadingCustomers: false,
  isLoadingDropdownCustomers: false,
  isCreatingCustomers: false,
  isSuccess: false,
  isError: false,
};

export interface IFilterCustomer {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}

export const getSearchCustomers = createAsyncThunk(
  "customers/search",
  async (filterCustomer: IFilterCustomer, thunkAPI) => {
    try {
      return await customersService.search(filterCustomer);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getManyCustomers = createAsyncThunk(
  "customers/many",
  async ({ _ids }: { _ids: string }, thunkAPI) => {
    try {
      return await customersService.getManyCustomers({ _ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getCustomers = createAsyncThunk(
//   "customers",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await customersService.customers(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectCustomer = createAsyncThunk(
  "customers/selectCustomer",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await customersService.customer({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const contactsByCustomerId = createAsyncThunk(
  "customers/getContactsByCustomerId",
  async (_id: string, thunkAPI) => {
    try {
      return await usersService.getContactsByCustomerId({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/update",
  async (
    { _customer, noToast }: { _customer: ICustomer; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await customersService.update({ _customer });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCustomerContacts = createAsyncThunk(
  "customers/updateContacts",
  async (
    {
      _customer,
      contacts,
      noToast,
    }: { _customer: string; contacts: string[]; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await customersService.updateContacts({ _customer, contacts });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getAgideskCustomers = createAsyncThunk(
//   "customers/sync",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await customersService.syncCustomers(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const deleteCustomer = createAsyncThunk(
  "customers/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await customersService.deleteCustomers({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customers/create",
  async (newCustomer: INewCustomer, thunkAPI) => {
    try {
      return await customersService.create(newCustomer);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectCustomers(state, action: PayloadAction<ICustomer[]>) {
      state.customers = action.payload || [];
    },
    selectTotalCustomers(state, action: PayloadAction<number>) {
      state.totalCustomers = action.payload || 0;
    },
    reselectCustomer(state, action: PayloadAction<ICustomer | null>) {
      state.selectedCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // CUSTOMERS
      .addCase(getSearchCustomers.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingCustomers = true;
        }
        state.isLoadingDropdownCustomers = true;
      })
      .addCase(getSearchCustomers.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.customers = action.payload.results || [];
        // if (state.totalCustomers <= action.payload.count)
        state.totalCustomers = action.payload.count;
        state.isLoadingCustomers = false;
        state.isLoadingDropdownCustomers = false;
      })
      .addCase(getSearchCustomers.rejected, (state) => {
        state.isError = true;
        state.customers = [];
        state.isLoadingCustomers = false;
        state.isLoadingDropdownCustomers = false;
      })
      // SHOW CUSTOMER
      .addCase(selectCustomer.pending, (state) => {
        state.isLoadingCustomers = true;
      })
      .addCase(selectCustomer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.selectedCustomer = action.payload || null;
        state.isLoadingCustomers = false;
      })
      .addCase(selectCustomer.rejected, (state) => {
        state.isError = true;
        state.selectedCustomer = null;
        state.isLoadingCustomers = false;
      })
      // GET CONTACTS BY CUSTOMER
      .addCase(contactsByCustomerId.pending, (state) => {
        state.isLoadingCustomers = true;
      })
      .addCase(contactsByCustomerId.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (state.selectedCustomer)
          state.selectedCustomer.contacts = action.payload || [];
        state.isLoadingCustomers = false;
      })
      .addCase(contactsByCustomerId.rejected, (state) => {
        state.isError = true;
        state.selectedCustomer = null;
        state.isLoadingCustomers = false;
      })
      // UPDATE CUSTOMER CONTACTS
      .addCase(updateCustomerContacts.pending, () => {
        // state.isLoadingCustomers = true;
      })
      .addCase(updateCustomerContacts.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingCustomers = false;
        // state.selectedCustomer = action.payload || null;
      })
      .addCase(updateCustomerContacts.rejected, (state) => {
        state.isError = true;
        state.selectedCustomer = null;
        state.isLoadingCustomers = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateCustomer.pending, (/* state */) => {
        // state.isLoadingCustomers = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingCustomers = false;
        // state.selectedCustomer = action.payload || null;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.isError = true;
        state.selectedCustomer = null;
        state.isLoadingCustomers = false;
      })
      // AGI CUSTOMERS
      // .addCase(getAgideskCustomers.pending, (state) => {
      //   state.isLoadingCustomers = true;
      // })
      // .addCase(getAgideskCustomers.fulfilled, (state, action) => {
      //   state.isSuccess = true;
      //   state.customers = action.payload.results || [];
      //   state.isLoadingCustomers = false;
      // })
      // .addCase(getAgideskCustomers.rejected, (state) => {
      //   state.isError = true;
      //   state.customers = [];
      //   state.isLoadingCustomers = false;
      // })
      .addCase(createCustomer.pending, (state) => {
        state.isCreatingCustomers = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          !["undefined", "string"].includes(typeof action.payload)
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: "Cliente criado com sucesso!",
          });
          const newState = state.customers;
          newState.push(action.payload);
          state.customers = newState;
        } else {
          // if (
          //   typeof action.payload === "string" &&
          //   action.payload.includes("duplicate")
          // ) {
          customToast({
            type: IToastType.ERROR,
            message: `Opa! Já existe um cliente com esse nome!`,
          });
          // } else {
          // customToast({
          //   type: IToastType.ERROR,
          //   message: `Algo deu errado!`,
          // });
          // }
          state.isError = true;
        }
        state.isCreatingCustomers = false;
      })
      .addCase(createCustomer.rejected, (state) => {
        state.isError = true;
        state.isCreatingCustomers = false;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default customersSlice.reducer;
export const {
  logout,
  reselectCustomer,
  selectCustomers,
  selectTotalCustomers,
} = customersSlice.actions;
