/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Popup } from "semantic-ui-react";
import { twMerge } from "tailwind-merge";

const PopupComponent = ({
  red,
  className,
  classLabel,
  required,
  label,
  italic,
  content,
  onClick,
  icon,
  open,
  innerClassname,
}: {
  className?: string;
  classLabel?: string;
  icon?: string;
  red?: boolean;
  italic?: boolean;
  required?: boolean;
  label: string;
  content: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  open?: boolean;
  innerClassname?: string;
}) => {
  const getColor = () => {
    if (red) return "text-red";
    return "text-blue-link";
  };
  const getIcon = () => {
    if (icon) return icon;
    return "las la-info-circle";
  };

  return (
    <div
      className={className || "flex items-center mt-3 -mb-3"}
      role="presentation"
      onClick={onClick}
    >
      {typeof onClick === "undefined" ? (
        <label>
          <div className={twMerge("flex", classLabel)}>
            {label}
            {required ? <h1 className="text-red">*</h1> : null}
          </div>
        </label>
      ) : null}
      <Popup
        // flowing
        open={open}
        positionFixed
        position="right center"
        content={content}
        trigger={
          <div className={twMerge("flex items-center", innerClassname)}>
            <i
              className={`${getIcon()} ${getColor()} text-[16px] cursor-pointer ${
                typeof onClick !== "undefined" ? "mr-4" : "ml-2"
              }`}
            />
            {typeof onClick !== "undefined" ? (
              <label
                className={`${
                  italic ? "italic" : ""
                } font-normal cursor-pointer ${classLabel} `}
              >
                <div className="flex">{label}</div>
              </label>
            ) : null}
          </div>
        }
      />
    </div>
  );
};

PopupComponent.defaultProps = {
  className: "",
  classLabel: "",
  icon: "",
  required: false,
  red: false,
  italic: false,
  onClick: undefined,
};

export default PopupComponent;
