/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { usersService, teamsService } from "../services";
import { IUser } from "./users.slice";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IOfficehour } from "./officehours.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { ISurvey } from "./surveys.slice";

export interface UserSettings {
  user: string;
  order?: number;
  limit?: number | null;
  activechats?: number;
  lastassigned?: boolean;
}
export type UsersSettings = UserSettings[];

export enum ChatRoutingType {
  ROUNDROBIN = "ROUNDROBIN",
  SKILL = "SKILL",
  RANDOM = "RANDOM",
}

export interface DropdownTeam {
  _id: string;
  name: string;
  deleted?: boolean;
  active?: boolean;
  offline?: boolean;
}

export enum AssignedType {
  AGENT = "AGENT",
  BACKLOG = "BACKLOG",
}

export interface IProgramedExcluding {
  chats?: {
    period?: number;
    customers: string[];
    contacts: string[];
  };
  attachments?: {
    period?: number;
    customers: string[];
    contacts: string[];
  };
}

export interface ITeam {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  name?: string;
  active?: boolean;
  externalcode?: string;
  users?: IUser[];
  chatusers?: UsersSettings;
  routingtype?: ChatRoutingType;
  chatlimit?: boolean;
  chatlimitsize?: number;
  assignedbacklog?: boolean;
  backlogsize?: number;
  assignedtype?: AssignedType;
  officehour?: IOfficehour;
  survey?: ISurvey;
  programed_excluding?: IProgramedExcluding;
}

export type Teams = ITeam[];

export interface INewTeam {
  name: string;
}

interface TeamsState extends AsyncState {
  isLoadingTeams: boolean;
  isLoadingDropdownTeams: boolean;
  isCreatingTeams: boolean;
  isRemoving: boolean;
  teams: Teams;
  totalTeams: number;
  selectedTeam: ITeam | null;
}

const initialState: TeamsState = {
  teams: [],
  selectedTeam: null,
  totalTeams: 0,
  isRemoving: false,
  isLoadingTeams: false,
  isCreatingTeams: false,
  isLoadingDropdownTeams: false,
  isSuccess: false,
  isError: false,
};

export interface IFilterTeam {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
  withChatusers?: boolean;
}

export const getSearchTeams = createAsyncThunk(
  "teams/search",
  async (filterTeam: IFilterTeam, thunkAPI) => {
    try {
      return await teamsService.search(filterTeam);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchFilterTeams = createAsyncThunk(
  "teamsfilter/search",
  async (_ids: string[], thunkAPI) => {
    try {
      return await usersService.postTeamsByUserId({ _ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getTeams = createAsyncThunk(
//   "teams",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await teamsService.teams(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const teamsChatsAssignment = createAsyncThunk(
  "teams/chatassignment",
  async (_, thunkAPI) => {
    try {
      return await teamsService.reassignment();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const teamsAssignment = createAsyncThunk(
  "teams/assignment",
  async (
    {
      teamid,
      contactid,
      lastuserid,
    }: {
      teamid: string;
      contactid?: string;
      lastuserid?: string;
    },
    thunkAPI
  ) => {
    try {
      return await teamsService.assignment({ teamid, contactid, lastuserid });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateChatUsers = createAsyncThunk(
  "teams/chatusers",
  async (
    {
      user,
      team,
      action,
      lastassigned,
    }: {
      user: string;
      team: string;
      action?: string;
      lastassigned?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await teamsService.chatUsers({
        user,
        _team: team,
        action,
        lastassigned,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersByTeamId = createAsyncThunk(
  "teams/getUsersByTeamId",
  async ({ _id }: { _id: string | undefined }, thunkAPI) => {
    try {
      if (_id) return await usersService.getAgentsByTeamId({ _id });
      return null;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const chatusersByTeamId = createAsyncThunk(
  "teams/postChatusersByTeamId",
  async ({ _id }: { _id: string | undefined }, thunkAPI) => {
    try {
      if (_id) return await teamsService.postChatusersByTeamId({ _id });
      return null;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "teams/update",
  async ({ _team, noToast }: { _team: any; noToast?: boolean }, thunkAPI) => {
    try {
      return await teamsService.update({ _team });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTeamUsers = createAsyncThunk(
  "teams/updateUsers",
  async (
    {
      _team,
      users,
      noToast,
    }: { _team: string; users: string[]; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await teamsService.updateUsers({ _team, users });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getAgideskTeams = createAsyncThunk(
//   "teams/sync",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await teamsService.syncTeams(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const deleteTeam = createAsyncThunk(
  "teams/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await teamsService.deleteTeams({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTeam = createAsyncThunk(
  "teams/create",
  async (newTeam: INewTeam, thunkAPI) => {
    try {
      return await teamsService.create(newTeam);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectTeam = createAsyncThunk(
  "teams/selectTeam",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await teamsService.team({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectTeams(state, action: PayloadAction<ITeam[]>) {
      state.teams = action.payload || [];
    },
    selectTotalTeams(state, action: PayloadAction<number>) {
      state.totalTeams = action.payload || 0;
    },
    reselectTeam(state, action: PayloadAction<ITeam | null>) {
      state.selectedTeam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // TEAMS
      .addCase(getSearchTeams.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingTeams = true;
        }
        state.isLoadingDropdownTeams = true;
      })
      .addCase(getSearchTeams.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.teams = action.payload.results || [];
        // if (state.totalTeams <= action.payload.count)
        state.totalTeams = action.payload.count;
        state.isLoadingTeams = false;
        state.isLoadingDropdownTeams = false;
      })
      .addCase(getSearchTeams.rejected, (state) => {
        state.isError = true;
        state.teams = [];
        state.isLoadingTeams = false;
        state.isLoadingDropdownTeams = false;
      })
      // SHOW TEAM
      .addCase(selectTeam.pending, (state) => {
        state.isLoadingTeams = true;
      })
      .addCase(selectTeam.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.selectedTeam = action.payload || null;
        state.isLoadingTeams = false;
      })
      .addCase(selectTeam.rejected, (state) => {
        state.isError = true;
        state.selectedTeam = null;
        state.isLoadingTeams = false;
      })
      // GET USERS BY TEAM
      .addCase(usersByTeamId.pending, (state) => {
        state.isLoadingTeams = true;
      })
      .addCase(usersByTeamId.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (state.selectedTeam) state.selectedTeam.users = action.payload || [];
        state.isLoadingTeams = false;
      })
      .addCase(usersByTeamId.rejected, (state) => {
        state.isError = true;
        state.selectedTeam = null;
        state.isLoadingTeams = false;
      })
      .addCase(teamsChatsAssignment.pending, () => {
        // state.isLoadingTeams = true;
      })
      .addCase(teamsChatsAssignment.fulfilled, (state) => {
        state.isSuccess = true;
        // if (state.selectedTeam) state.selectedTeam.users = action.payload || [];
        // state.isLoadingTeams = false;
      })
      .addCase(teamsChatsAssignment.rejected, (state) => {
        state.isError = true;
        // state.selectedTeam = null;
        // state.isLoadingTeams = false;
      })
      // GET CHATUSERS BY TEAM
      .addCase(getSearchFilterTeams.pending, (state) => {
        state.isLoadingTeams = true;
        state.isLoadingDropdownTeams = true;
      })
      .addCase(getSearchFilterTeams.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingTeams = false;
        state.isLoadingDropdownTeams = false;
      })
      .addCase(getSearchFilterTeams.rejected, (state) => {
        state.isError = true;
        state.selectedTeam = null;
        state.isLoadingTeams = false;
        state.isLoadingDropdownTeams = false;
      })
      .addCase(chatusersByTeamId.pending, (state) => {
        state.isLoadingTeams = true;
      })
      .addCase(chatusersByTeamId.fulfilled, (state) => {
        state.isSuccess = true;
        // if (state.selectedTeam)
        //   state.selectedTeam.chatusers = action.payload || [];
        state.isLoadingTeams = false;
      })
      .addCase(chatusersByTeamId.rejected, (state) => {
        state.isError = true;
        state.selectedTeam = null;
        state.isLoadingTeams = false;
      })
      // UPDATE TEAM USERS
      .addCase(updateTeamUsers.pending, () => {
        // state.isLoadingTeams = true;
      })
      .addCase(updateTeamUsers.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingTeams = false;
      })
      .addCase(updateTeamUsers.rejected, (state) => {
        state.isError = true;
        state.selectedTeam = null;
        state.isLoadingTeams = false;
      })
      // UPDATE TEAM
      .addCase(updateTeam.pending, () => {
        // state.isLoadingTeams = true;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            if (
              ["This team is the unique configure in external app"].includes(
                action?.payload?.message
              )
            ) {
              customToast({
                type: IToastType.WARNING,
                message:
                  "Ação interrompida. Única equipe configurada em um aplicativo externo. Verifique suas integrações.",
              });
            } else if (
              [
                "This team is the unique configure in general settings",
              ].includes(action?.payload?.message)
            ) {
              customToast({
                type: IToastType.WARNING,
                message:
                  "Ação interrompida. Única equipe configurada para atendimento pelo widget. Verifique suas configurações gerais.",
              });
            } else {
              customToast({
                type: IToastType.ERROR,
                message: `Algo deu errado!`,
              });
            }
          }
          state.isError = true;
        }
        state.isLoadingTeams = false;
        // state.selectedTeam = action.payload || null;
      })
      .addCase(updateTeam.rejected, (state) => {
        state.isError = true;
        state.selectedTeam = null;
        state.isLoadingTeams = false;
      })
      // AGI TEAMS
      // .addCase(getAgideskTeams.pending, (state) => {
      //   state.isLoadingTeams = true;
      // })
      // .addCase(getAgideskTeams.fulfilled, (state, action) => {
      //   state.isSuccess = true;
      //   state.teams = action.payload.results || [];
      //   state.isLoadingTeams = false;
      // })
      // .addCase(getAgideskTeams.rejected, (state) => {
      //   state.isError = true;
      //   state.teams = [];
      //   state.isLoadingTeams = false;
      // })
      .addCase(createTeam.pending, (state) => {
        state.isCreatingTeams = true;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          !["undefined", "string"].includes(typeof action.payload)
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: "Equipe criada com sucesso!",
          });
          const newState = state.teams;
          newState.push(action.payload);
          state.teams = newState;
        } else {
          // if (
          //   typeof action.payload === "string" &&
          //   action.payload.includes("duplicate")
          // ) {
          customToast({
            type: IToastType.ERROR,
            message: `Opa! Já existe uma equipe com esse nome!`,
          });
          // } else {
          // customToast({
          //   type: IToastType.ERROR,
          //   message: `Algo deu errado! Tente novamente.`,
          // });
          // }
          state.isError = true;
        }
        state.isCreatingTeams = false;
      })
      .addCase(createTeam.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      })
      .addCase(deleteTeam.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          if (
            ["This team is the unique configure in external app"].includes(
              action?.payload?.message
            )
          ) {
            customToast({
              type: IToastType.WARNING,
              message:
                "Ação interrompida. Única equipe configurada em um aplicativo externo. Verifique suas integrações.",
            });
          } else if (
            ["This team is the unique configure in general settings"].includes(
              action?.payload?.message
            )
          ) {
            customToast({
              type: IToastType.WARNING,
              message:
                "Ação interrompida. Única equipe configurada para atendimento pelo widget. Verifique suas configurações gerais.",
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isRemoving = false;
        }
      })
      .addCase(deleteTeam.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default teamsSlice.reducer;
export const { logout, reselectTeam, selectTeams, selectTotalTeams } =
  teamsSlice.actions;
