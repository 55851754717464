import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/redux/hooks";
import { updateTenant } from "../services/instances";

const PrivateRoute = () => {
  const { isAuthenticated, user, tenantID, isWidgetAgent, isWidgetContact } =
    useAppSelector((state) => state.auth);
  const location = useLocation();

  const getWidget = () => {
    if (isWidgetAgent) {
      return "agent";
    }
    if (isWidgetContact) {
      return "contact";
    }
    return undefined;
  };

  if (tenantID) {
    updateTenant(tenantID || user?.tenant || "", getWidget());
  } else if (isAuthenticated) {
    updateTenant(tenantID || user?.tenant || "", getWidget());
  }

  const getFormScreen = () => {
    if (tenantID && tenantID.length > 0) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Navigate to="/company" state={{ from: location }} replace />;
  };

  return isAuthenticated ? <Outlet /> : getFormScreen();
};

export default PrivateRoute;
