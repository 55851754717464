/* eslint-disable no-nested-ternary */
import { IFile } from "../shared/models/interfaces/file.interface";
import removeDuplicates from "../shared/utils/removeDuplicates";
import {
  ChatAdmin,
  ChatRead,
  ChatStatus,
  IChat,
  INewChat,
} from "../slices/chats.slice";
import { IUser } from "../slices/users.slice";
import instances from "./instances";

const search = async ({
  skip,
  limit,
  filter,
  verb,
  history,
  code,
  sort,
}: {
  skip?: number;
  limit?: number;
  filter?: object;
  verb?: string;
  history?: boolean;
  code?: boolean;
  sort?: number;
}) => {
  try {
    const _verb = typeof verb !== "undefined" ? verb : "get";

    const fields =
      "users.tenant,avatar.name,avatar.path,groupchat,offline,groupsettings,name,users.name,users.roles,users.customers,users.teams,users.tags,users.active,users.spam,users.externalcode,users.deleted,users.status,users.phone,users.email,users.updated_at,lastmessage.commenttemplate,lastmessage.read,lastmessage.content,lastmessage.created_at,lastmessage.files,lastmessage.internal,team.name,team.chatlimitsize,team.assignedtype,team.chatusers,team.programed_excluding,notifications,firstnotread,type,status,updated_at,updated_by,transferred,externalapp._id,externalapp.type,externalapp.fields.name,externalapp.fields.appkey,externalapp.fields.apiurl,externalapp.fields.botid,transferredteams._id,transferredteams.name,externalcode,read,created_by";

    const fieldsHistory =
      "users.tenant,avatar.name,avatar.path,groupchat,offline,name,users.name,users.roles,users.customers,users.teams,users.tags,users.active,users.spam,users.externalcode,users.deleted,users.status,users.phone,users.email,users.updated_at,lastmessage.commenttemplate,lastmessage.created_at,team.name,team.chatlimitsize,team.assignedtype,team.chatusers,team.programed_excluding,type,status,updated_at,updated_by,transferred,externalapp._id,externalapp.type,externalapp.fields.name,externalapp.fields.appkey,externalapp.fields.apiurl,externalapp.fields.botid,transferredteams._id,transferredteams.name,externalcode,read,created_by";

    const _fields = typeof history === "undefined" ? fields : fieldsHistory;

    const _fieldsearchPars = {
      skip: skip || 0,
      limit: limit || 10,
      filter: typeof verb === "undefined" ? JSON.stringify(filter) : filter,
      fields:
        typeof code !== "undefined" && code === true
          ? _fields.concat(",users.code")
          : _fields,
      history: typeof history !== "undefined" ? true : undefined,
      sort: sort ?? undefined,
    };

    const _instanceVerb =
      _verb === "post"
        ? instances.instanceChat.post
        : instances.instanceChat.get;

    const response = await (_verb === "post"
      ? _instanceVerb("/search", _fieldsearchPars)
      : _instanceVerb("", { params: _fieldsearchPars }));
    const _response = response.data;
    return _response;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const advancedSearch = async ({
  skip,
  limit,
  filter,
  history,
  code,
  sort,
}: {
  skip?: number;
  limit?: number;
  filter?: object;
  history?: boolean;
  code?: boolean;
  sort?: number;
}) => {
  const params = {
    skip,
    limit,
    filter,
    history,
    code,
    sort,
  };
  try {
    return search({
      ...params,
      verb: "post",
    });
  } catch (error) {
    return null;
  }
};

const chat = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceChat.get(`/${_id}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async (newChat: INewChat): Promise<IChat | null> => {
  try {
    const {
      users,
      type,
      status,
      team,
      externalapp,
      groupchat,
      groupsettings,
      name,
      externalcodephone,
    } = newChat;
    const data = {
      name,
      groupchat: groupchat || false,
      groupsettings,
      users,
      type,
      status,
      team,
      externalapp,
      externalcodephone,
    };
    const response = await instances.instanceChat.post("", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const read = async (_chat: { _id: string; read: ChatRead[] }) => {
  try {
    const data = _chat.read;
    const response = await instances.instanceChat.patch(
      `${_chat._id}/read`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _chat }: { _chat: any }) => {
  try {
    const data = _chat;
    const response = await instances.instanceChat.patch(`${_chat._id}`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const spamContactChats = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { _id: ids, spam: true };
    const response = await instances.instanceChat.patch("/spam", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const addTagsToChat = async ({
  _id,
  tags,
}: {
  _id: string;
  tags: string[];
}): Promise<IChat | null> => {
  try {
    const data = { tags };
    const response = await instances.instanceChat.patch(`${_id}/tags`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const inactiveContactChats = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { _id: ids, inactive: true };
    const response = await instances.instanceChat.patch("/spam", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const updateGroup = async ({
  _chat,
}: {
  _chat: {
    _id: string;
    name?: string;
    avatar?: IFile | string | null;
    users?: IUser[] | string[];
    groupsettings?: ChatAdmin[];
  };
}) => {
  try {
    const data = {
      ..._chat,
      users: removeDuplicates(_chat?.users),
      groupsettings: _chat.groupsettings?.map((_groupsetting) => {
        const _setting: ChatAdmin = {
          ..._groupsetting,
          user:
            typeof _groupsetting?.user === "string"
              ? _groupsetting.user
              : (_groupsetting.user?._id as any),
        };
        return _setting;
      }),
    };
    // console.log(data);
    // const data = _chat;
    const response = await instances.instanceChat.patch(
      `${_chat._id}/chatsettings`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const transfer = async ({
  _id,
  users,
  to,
  team,
  auto,
  status,
}: {
  _id: string;
  users: string[];
  to?: string;
  team: string;
  auto?: boolean;
  status: ChatStatus;
}) => {
  try {
    const data = to
      ? { users, to, team, auto, status }
      : { users, team, auto, status };
    const response = await instances.instanceChat.patch(
      `${_id}/transfer`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteAll = async () => {
  try {
    const response = await instances.instanceChat.delete("");
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const getChatTemplate = async (_id: string) => {
  try {
    const response = await instances.instanceChat.get(`/chat-template/${_id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

const chatsService = {
  search,
  advancedSearch,
  chat,
  create,
  deleteAll,
  read,
  update,
  updateGroup,
  transfer,
  spamContactChats,
  inactiveContactChats,
  addTagsToChat,
  getChatTemplate,
};

export default chatsService;
