/* eslint-disable no-restricted-syntax */
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { Sidebar, Table } from "semantic-ui-react";
import {
  ICardItems,
  ISurveyRating,
  ISurveyStats,
} from "../slices/surveys.slice";
import SearchbarFilters from "../shared/components/SearchbarFilters.component";
import { IFilterProps } from "../slices/chats.slice";
import StatisticCard from "../features/surveys/components/StatisticCard.component";
import TableBody from "../shared/components/Tables/TableBody.component";
import TableHeader from "../shared/components/Tables/TableHeader.component";
import SelectPerPage from "../shared/components/Tables/SelectPerPage.component";
import PaginationComponent from "../shared/components/Tables/Pagination.component";
import { SURVEYRATING_COLUMNS } from "../shared/components/Tables/columns";
import LinkButton from "../shared/components/Buttons/LinkButton.component";
import LoadingDots from "../shared/components/LoadingDots.component";
import { surveysService } from "../services";
import { SidebarComponent } from "../shared/components/Sidebars";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import Button from "../shared/components/Buttons/Button.component";
import NotificationBadge from "../features/chats/components/NotificationBadge.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import removeDuplicates from "../shared/utils/removeDuplicates";
import {
  IChatSearchFilters,
  IFiltersDropdownInstances,
} from "../shared/components/@types/searchBarFilter.types";

const SurveyResultsPage = ({ toggleSidebar }: { toggleSidebar: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [surveyRatings, setSurveyRatings] = useState<ISurveyRating[]>([]);
  const [surveyRatingStats, setSurveyRatingStats] = useState<ISurveyStats>();
  const [totalSurveyRatings, setTotalSurveyRatings] = useState<number>(0);
  const [skipSearchPage, setSkipSearchPage] = useState<number>(0);
  const [activeSearchPage, setActiveSearchPage] = useState<number>(1);
  const [limitSearch, setLimitSearch] = useState<number>(5);

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [isLoadingSurveyRatings, setIsLoadingSurveyRatings] =
    useState<boolean>(false);
  const [reloadForced, setReloadForced] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const pageSurveysCount =
    typeof totalSurveyRatings !== "undefined" &&
    typeof limitSearch !== "undefined" &&
    totalSurveyRatings > 0
      ? Math.ceil(totalSurveyRatings / limitSearch)
      : 0;

  const defaultSurveyFilters: IFiltersDropdownInstances = {
    period: [],
    teams: [],
    users: [],
    customers: [],
    status: [],
    surveys: [],
  };

  const defaultStatisticItems = [
    {
      title: "Pesquisas de satisfação",
      icon: "las la-smile",
      divider: true,
      value: 0,
      type: "",
    },
    {
      title: "Enviadas",
      icon: "lar la-paper-plane",
      color: "text-blue-survey",
      value: 0,
      type: "sended",
    },
    {
      title: "Respondidas",
      icon: "las la-check-double",
      color: "text-green",
      value: 0,
      type: "answered",
    },
    {
      title: "Pendentes",
      icon: "las la-hourglass-end",
      color: "text-agidesk",
      value: 0,
      type: "pending",
    },
    {
      title: "Expiradas",
      icon: "las la-exclamation",
      color: "text-red",
      value: 0,
      type: "expired",
    },
    {
      title: "Índice de satisfação",
      icon: "las la-percent",
      divider: true,
      value: 0,
      type: "",
    },
    {
      title: "NPS",
      icon: "las la-signal",
      color: "text-green",
      value: 0,
      count: 0,
      avg: true,
      type: "npsavg",
    },
    {
      title: "Aprovação",
      icon: "las la-thumbs-up",
      color: "text-green",
      value: 0,
      total: 0,
      count: 0,
      percent: true,
      avg: true,
      type: "approvalavg",
    },
    {
      title: "Qualidade",
      icon: "las la-star",
      color: "text-green",
      value: 0,
      total: 0,
      count: 0,
      percent: true,
      avg: true,
      type: "qualityavg",
    },
    {
      title: "Sentimento",
      icon: "las la-smile",
      color: "text-green",
      value: 0,
      total: 0,
      count: 0,
      percent: true,
      avg: true,
      type: "feelingavg",
    },
  ];

  const [statisticItems, setStatisticItems] = useState<ICardItems[]>(
    defaultStatisticItems
  );

  const [searchFiltersSelected, setSearchFiltersSelected] =
    useState<IFiltersDropdownInstances>(defaultSurveyFilters);
  const [searchFiltersOptions, setSearchFiltersOptions] =
    useState<IFiltersDropdownInstances>(defaultSurveyFilters);

  const formatStatistics = (stats: ISurveyStats) => {
    if (typeof stats !== "undefined") {
      const items =
        typeof statisticItems !== "undefined"
          ? statisticItems
          : defaultStatisticItems;
      const newStats: ICardItems[] = [];
      Object.entries(items).forEach((item) => {
        const _item = item[1];
        const type = _item.type as keyof ISurveyStats;

        const avgIndex = type.indexOf("avg");
        const countIndex = `${type.slice(0, avgIndex)}qtd`;
        const value = stats[type];
        const total = stats.answered;
        const count = stats[countIndex as keyof ISurveyStats] || 0;
        newStats.push({
          ..._item,
          value,
          count,
          total,
        } as ICardItems);
      });
      setStatisticItems(newStats);
    }
  };

  const formatFilters = () => {
    const currentFilters = { ...searchFiltersSelected };

    const filterProps = {
      surveys:
        typeof currentFilters.surveys !== "undefined" &&
        currentFilters.surveys.length > 0
          ? currentFilters.surveys
          : undefined,
      users:
        typeof currentFilters.users !== "undefined" &&
        currentFilters.users.length > 0
          ? currentFilters.users
          : undefined,
      team:
        typeof currentFilters.teams !== "undefined" &&
        currentFilters.teams.length > 0
          ? currentFilters.teams
          : undefined,
      customer:
        typeof currentFilters.customers !== "undefined" &&
        currentFilters.customers.length > 0
          ? currentFilters.customers
          : undefined,
      status:
        typeof currentFilters.status !== "undefined" &&
        currentFilters.status.length > 0
          ? currentFilters.status
          : undefined,
      period:
        typeof currentFilters.period !== "undefined" &&
        currentFilters.period !== null
          ? currentFilters.period
          : undefined,
    } as IFilterProps;

    const filter = filterProps;

    Object.keys(filter).forEach((key) => {
      const currentValue = JSON.stringify(filter[key as keyof IFilterProps]);
      if (
        currentValue === "[]" ||
        currentValue === undefined ||
        currentValue === ""
      ) {
        delete filter[key as keyof IFilterProps];
      }
    });

    return filter;
  };

  const clearFilters = ({ _instance }: { _instance?: string }) => {
    if (typeof _instance !== "undefined" && ["users"].includes(_instance)) {
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
    } else {
      document
        .querySelectorAll(".dropdown-filters .clear")
        .forEach((el: any) => el.click());

      // clearStoragedFilters();

      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      if (typeof setSearchFiltersSelected !== "undefined")
        setSearchFiltersSelected(defaultSurveyFilters);
      if (typeof setSearchFiltersOptions !== "undefined")
        setSearchFiltersOptions(defaultSurveyFilters);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoadingSurveyRatings(true);
      const filters = formatFilters();
      const payload = await surveysService.searchRatings({
        skip: skipSearchPage,
        limit: limitSearch,
        filter: filters,
        deleted: false,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoadingSurveyRatings(false);
        setSurveyRatings(payload.results);
        setTotalSurveyRatings(payload.count);
        setSurveyRatingStats(payload.surveyStats);
        if (isLoadingPage) setIsLoadingPage(false);
      }
    })();
  }, [skipSearchPage, limitSearch, searchFiltersSelected]);

  useEffect(() => {
    const stats = surveyRatingStats as ISurveyStats;
    formatStatistics(stats);
  }, [surveyRatingStats]);

  const columns: readonly Column<object>[] = useMemo(
    () => SURVEYRATING_COLUMNS,
    []
  );
  const data: readonly object[] = useMemo(() => surveyRatings, [surveyRatings]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const countSelectedFilters = () => {
    let selectedFilters = 0;
    if (typeof searchFiltersSelected !== "undefined") {
      for (const _instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          searchFiltersSelected[_instance as keyof IChatSearchFilters];
        if (
          typeof instanceValues !== "undefined" &&
          instanceValues.length > 0
        ) {
          selectedFilters +=
            typeof instanceValues === "string" ? 1 : instanceValues.length;
        }
      }
    }
    return selectedFilters;
  };

  const showAppliedFilters = () => {
    if (countSelectedFilters() <= 0) {
      return "";
    }
    const showFilters: string[] = [];
    if (
      typeof searchFiltersSelected !== "undefined" &&
      typeof searchFiltersOptions !== "undefined"
    ) {
      for (const instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          searchFiltersSelected[instance as keyof IFiltersDropdownInstances] ||
          [];
        const instanceOptions =
          searchFiltersOptions[instance as keyof IFiltersDropdownInstances] ||
          [];

        if (
          typeof instanceValues !== "undefined" &&
          Array.isArray(instanceValues) &&
          instanceValues.length > 0
        ) {
          if (instanceValues.length > 0 && Array.isArray(instanceOptions)) {
            const items = instanceValues.map((_value) => {
              const value = _value;
              const result = instanceOptions.find((item: any) => {
                return item.key === value;
              });
              return result?.text;
            });
            // showFilters.push(`${getDropdownLabel(instance)} : ${items.join(', ')}`)
            showFilters.push(`${removeDuplicates(items).join(", ")}`);
            // showFilters.push(`${items.join(", ")}`);
          }
        } else if (
          Array.isArray(instanceOptions) &&
          instanceOptions.length > 0
        ) {
          const result = instanceOptions.find(
            (item) => item.key === instanceValues
          );
          if (typeof result?.text !== "undefined") {
            // showFilters.push(`${getDropdownLabel(instance)} : ${result?.text}`)
            showFilters.push(`${result?.text}`);
          }
        }
      }
    }
    const _filters: string = showFilters
      .filter((_filter) => _filter && _filter.length > 0)
      .join("; ");
    return (
      <div className="ml-3 text-[12px] text-break">
        {typeof searchFiltersSelected !== "undefined" ? (
          <div className="flex">
            <h2 className="font-semibold mr-2">Filtrando por:</h2>
            <h2>{`${
              _filters.length > 200
                ? _filters.substring(0, 200).concat("...")
                : _filters
            }`}</h2>
          </div>
        ) : null}
        <div className="w-full -ml-2">
          <LinkButton
            label="Limpar"
            leftIcon="las la-eraser -mr-2 ml-1"
            onClick={
              typeof clearFilters !== "undefined" ? clearFilters : () => null
            }
            extraClass="text-[12px]"
          />
        </div>
      </div>
    );
  };

  const goBack = () => {
    navigate("/settings/surveys");
  };

  const surveyRatingGrid = () => {
    const alreadyLoaded =
      rows.length > 0 ? (
        <Table
          id="table"
          {...getTableProps({
            className: "bg-white p-0 m-0",
          })}
          selectable={false}
          basic="very"
        >
          <TableHeader
            headerGroups={headerGroups}
            className="surveyrating border-l-4 border-l-white"
            selection={false}
          />
          <TableBody
            type="surveyrating"
            prepareRow={prepareRow}
            getTableBodyProps={getTableBodyProps}
            page={rows}
            selectedRow={[]}
            selection={false}
          />
        </Table>
      ) : (
        <div className="bg-white flex w-full h-full justify-center p-8">
          Nenhum resultado
        </div>
      );
    return (
      <div className="relative bg-white p-8">
        {isLoadingSurveyRatings ? (
          <LoadingDots
            className="flex justify-center items-center w-full h-full p-8"
            medium
          />
        ) : (
          alreadyLoaded
        )}
        {!isLoadingSurveyRatings &&
        rows.length > 0 &&
        typeof limitSearch !== "undefined" &&
        typeof activeSearchPage !== "undefined" ? (
          <div className="flex justify-between rounded-md">
            <SelectPerPage
              value={limitSearch}
              pagination={5}
              onChange={(e) => {
                if (
                  setLimitSearch &&
                  setActiveSearchPage &&
                  setSkipSearchPage
                ) {
                  setLimitSearch(+e.target.value);
                  setActiveSearchPage(1);
                  setSkipSearchPage(0);
                }
                // loadSurveys(0, +e.target.value);
              }}
            />
            <PaginationComponent
              table
              totalPages={pageSurveysCount}
              activePage={activeSearchPage}
              onPageChange={(_, _data) => {
                if (
                  _data.activePage &&
                  setActiveSearchPage &&
                  setSkipSearchPage
                ) {
                  setActiveSearchPage(+_data.activePage);
                  setSkipSearchPage(limitSearch * (+_data.activePage - 1));
                  // loadSurveys(limitSearch * (+_data.activePage - 1));
                }
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };
  if (isLoadingPage)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        medium
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        setShowFilter={setShowFilter}
        setReloadForced={setReloadForced}
        content={
          <div className="flex-container pl-[5px]">
            <div className="header relative bg-white rounded-md py-8 px-10">
              <h1 className="flex items-center justify-center font-semibold">
                <i className="las la-filter text-3xl" />

                <p className="text-xl ml-3">Filtrar resultados</p>
              </h1>
            </div>
            <SearchbarFilters
              clearFilters={clearFilters}
              searchFiltersOptions={searchFiltersOptions}
              searchFiltersSelected={searchFiltersSelected}
              setSearchFiltersOptions={setSearchFiltersOptions}
              setSearchFiltersSelected={setSearchFiltersSelected}
              setShowFilter={setShowFilter}
              setVisible={setVisible}
              defaultFilters={defaultSurveyFilters}
              searchInstanceName="surveyrating"
            />
          </div>
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created=""
          updated=""
          deleted=""
          title="Indicadores de satisfação"
          imported={false}
          icon="las la-trophy"
        />
        <div className="px-4 py-5 flex items-center justify-between md:p-0 w-full">
          <div className="md:flex items-center">
            {setVisible ? (
              <div className="relative">
                <Button
                  minWidth
                  extraClass="w-[90px] relative"
                  inverted
                  icon={showFilter ? "las la-times" : "las la-filter"}
                  label={showFilter ? "Fechar" : "Filtros"}
                  onClick={() => {
                    if (setVisible && setShowFilter) {
                      setVisible((prevState) => !prevState);
                      setShowFilter((prevState) => !prevState);
                      if (!location.hash.includes("#filters")) {
                        navigate(`${location.pathname}#filters`);
                      } else {
                        navigate(`${location.pathname}`);
                      }
                    }
                  }}
                />
                {typeof searchFiltersSelected !== "undefined" &&
                countSelectedFilters() > 0 ? (
                  <div className="absolute -right-1 -bottom-1">
                    <NotificationBadge
                      title="filtro(s)"
                      badge={countSelectedFilters()}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="ml-4">{showAppliedFilters()}</div>
          </div>
        </div>
        <div className="relative">
          <div
            key="statisticArea"
            className=" grid grid-cols-1 md:grid-cols-4 gap-2 py-5"
          >
            {/* <StatisticCard cols={4} items={statisticItems} /> */}
            <StatisticCard items={statisticItems} />
          </div>
        </div>

        <div className="text-xl font-medium p-2 grid">
          <div>
            <i className="las la-chart-bar" title="Resultados" /> Resultados
          </div>
        </div>
        {surveyRatingGrid()}
        <div className="mb-20" />
        <FooterPageInfo
          toggleSidebar={toggleSidebar}
          back={goBack}
          remove={goBack}
          save={() => null}
        />
      </Sidebar.Pusher>
    </div>
  );
};

export default SurveyResultsPage;
