import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
import notificationService from "./services/notification.service";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
const messaging = await (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    // console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    // console.log(err);
    return null;
  }
})();

export const requestPermission = async ({
  setToken,
  setIsTokenFound,
}: {
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setIsTokenFound: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // console.log("Requesting permission...");
  Notification.requestPermission().then(async (permission) => {
    // console.log(permission);
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      const currentToken = await getToken(messaging as Messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      if (currentToken) {
        setToken(currentToken);
        await notificationService.enable({ token: currentToken });
        setIsTokenFound(true);
      } else {
        await notificationService.disable();
        setIsTokenFound(false);
      }
    }
  });
};

export const fetchToken = async ({
  setToken,
  setIsTokenFound,
}: {
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setIsTokenFound: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  try {
    const currentToken = await getToken(messaging as Messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      setToken(currentToken);
      await notificationService.enable({ token: currentToken });
      setIsTokenFound(true);
    } else {
      await notificationService.disable();
      setIsTokenFound(false);
      requestPermission({ setToken, setIsTokenFound });
    }
  } catch (err) {
    // console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging as Messaging, (payload) => {
      resolve(payload);
    });
  });
