/* eslint-disable no-shadow */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IFilterDashboard } from "./dashboard.slice";
import {
  IAcceptedFile,
  IAcceptedFiles,
} from "../shared/models/interfaces/acceptedfile.interface";
import { IChat } from "./chats.slice";

export const dashboardDefault: IFilterDashboard = {
  teams: [],
  users: [],
  period: {},
  types: [],
  tags: [],
};

export interface IStorableFilters {
  _id?: string;
  dashboards?: IFilterDashboard;
}

export interface IParsedThumb {
  name: string;
  preview: string;
  lastModified: number;
  lastModifiedDate: Date;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export interface IChatPreferences {
  _id?: string;
  user?: string;
  chat?: IChat;
  read?: boolean;
  draftMessage?: string;
  thumbs?: IAcceptedFiles;
  parsedThumbs?: IParsedThumb[];
}

export enum ViewType {
  GRID = "GRID",
  TABLE = "TABLE",
}
export interface IViewMode {
  users: ViewType;
  contacts: ViewType;
  customers: ViewType;
  teams: ViewType;
  whatsapp: ViewType;
  agidesk: ViewType;
  botmaker: ViewType;
  vtex: ViewType;
  officehours: ViewType;
  holidays: ViewType;
  offlinemessages: ViewType;
  surveys: ViewType;
  campaigns: ViewType;
  contacttags: ViewType;
  chattags: ViewType;
  commenttemplates: ViewType;
}

export interface IUserPreferences {
  user?: string | undefined;
  chats?: IChatPreferences[] | undefined;
  dashboards?: {
    options: IFilterDashboard;
    selected: IFilterDashboard;
  };
  viewmode: IViewMode;
}

interface PreferencesState extends AsyncState {
  preferences: IUserPreferences[];
}

const initialState: PreferencesState = {
  preferences: [],
  isSuccess: false,
  isError: false,
};

export const prepareDraftThumbs = (chatPreferences: IChatPreferences) => {
  const draftThumbs =
    typeof chatPreferences !== "undefined" &&
    typeof chatPreferences?.thumbs !== "undefined"
      ? chatPreferences.thumbs
      : undefined;

  const _draftThumbsPrepared =
    typeof chatPreferences !== "undefined" &&
    typeof chatPreferences?.parsedThumbs !== "undefined"
      ? chatPreferences.parsedThumbs
      : undefined;

  window.URL = window.URL || window.webkitURL;
  const preparedThumbs: IAcceptedFile[] = [];
  if (
    typeof draftThumbs !== "undefined" &&
    typeof _draftThumbsPrepared !== "undefined"
  ) {
    draftThumbs.forEach((draftThumb, index) => {
      if (typeof draftThumb !== "undefined") {
        const thumbBlob = new Blob([
          new Uint8Array(draftThumb as unknown as ArrayBufferLike).buffer,
        ]);
        const _currentThumbParsed = _draftThumbsPrepared[index];
        const _newThumb = (
          typeof thumbBlob !== "undefined" &&
          typeof _currentThumbParsed !== "undefined"
            ? new File([thumbBlob], _currentThumbParsed.name, {
                type: _currentThumbParsed.type,
                lastModified: _currentThumbParsed.lastModified,
              })
            : undefined
        ) as IAcceptedFile;
        if (
          typeof thumbBlob !== "undefined" &&
          thumbBlob?.size > 0 &&
          typeof _newThumb !== "undefined"
        ) {
          preparedThumbs.push(
            Object.assign(_newThumb, {
              preview: window.URL.createObjectURL(_newThumb),
            })
          );
        }
      }
    });
    return preparedThumbs.length > 0 ? preparedThumbs : undefined;
  }
  return undefined;
};

const getUserPreferences = (state: PreferencesState, _user: string) => {
  const userIndex = state.preferences?.findIndex(
    (object) => object.user === _user
  );
  let currentUserPreferences =
    typeof state.preferences !== "undefined" &&
    typeof state.preferences[userIndex] !== "undefined"
      ? { ...state.preferences[userIndex] }
      : {
          user: _user,
          dashboards: undefined,
          chats: undefined,
          viewmode: {
            users: ViewType.GRID,
            contacts: ViewType.GRID,
            customers: ViewType.GRID,
            teams: ViewType.GRID,
            whatsapp: ViewType.GRID,
            agidesk: ViewType.GRID,
            botmaker: ViewType.GRID,
            vtex: ViewType.GRID,
            officehours: ViewType.GRID,
            holidays: ViewType.GRID,
            offlinemessages: ViewType.GRID,
            surveys: ViewType.GRID,
            campaigns: ViewType.GRID,
            contacttags: ViewType.GRID,
            chattags: ViewType.GRID,
            commenttemplates: ViewType.GRID,
          },
        };

  if (
    typeof state.preferences !== "undefined" &&
    typeof state.preferences[userIndex] !== "undefined" &&
    typeof state.preferences[userIndex].viewmode === "undefined"
  ) {
    currentUserPreferences = {
      ...state.preferences[userIndex],
      viewmode: {
        users: ViewType.GRID,
        contacts: ViewType.GRID,
        customers: ViewType.GRID,
        teams: ViewType.GRID,
        whatsapp: ViewType.GRID,
        agidesk: ViewType.GRID,
        botmaker: ViewType.GRID,
        vtex: ViewType.GRID,
        officehours: ViewType.GRID,
        holidays: ViewType.GRID,
        offlinemessages: ViewType.GRID,
        surveys: ViewType.GRID,
        campaigns: ViewType.GRID,
        contacttags: ViewType.GRID,
        chattags: ViewType.GRID,
        commenttemplates: ViewType.GRID,
      },
    };
  }

  return currentUserPreferences;
};

const setUserPreferences = (
  state: PreferencesState,
  _user: string,
  preferences: any
) => {
  const userIndex = state.preferences?.findIndex(
    (object) => object.user === _user
  );

  state.preferences =
    typeof state.preferences === "undefined" ? [] : state.preferences;

  if (typeof userIndex !== "undefined" && userIndex !== -1) {
    state.preferences[userIndex] = {
      ...state.preferences[userIndex],
      ...preferences,
    };
  } else {
    state.preferences.push({ user: _user, ...preferences });
  }
};

const prepareUserDashboardPreferences = (
  action: PayloadAction<IStorableFilters>,
  state: PreferencesState,
  preference: string
) => {
  const _user = action?.payload._id;
  if (typeof _user !== "undefined") {
    const currentPreferences = getUserPreferences(state, _user);
    const _dashboards =
      typeof action.payload.dashboards !== "undefined"
        ? {
            ...currentPreferences?.dashboards,
            [preference]: action.payload.dashboards,
          }
        : {};

    const newPreferences = {
      ...currentPreferences,
      dashboards: _dashboards,
    };

    setUserPreferences(state, _user, newPreferences);
    state.isSuccess = true;
  }
};

const handleChatUserPreferences = (
  state: PreferencesState,
  action: PayloadAction<IChatPreferences>
) => {
  const _user = action?.payload.user;
  const _selectedChat = action.payload?._id;

  if (typeof _user !== "undefined") {
    const currentPreferences = getUserPreferences(state, _user);

    const chats =
      typeof currentPreferences?.chats === "undefined"
        ? []
        : [...currentPreferences.chats];

    const chatIndex =
      typeof chats !== "undefined" &&
      chats.length > 0 &&
      typeof _selectedChat !== "undefined"
        ? chats.findIndex((object) => object._id === _selectedChat)
        : undefined;

    if (typeof chatIndex !== "undefined" && chatIndex !== -1) {
      chats[chatIndex] = action.payload;
    } else {
      chats.push(action.payload);
    }

    const newPreferences = {
      ...currentPreferences,
      chats,
    };

    setUserPreferences(state, _user, newPreferences);
    state.isSuccess = true;
  }
};

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    loadUserPreferences(state, action: PayloadAction<string>) {
      const userIndex = state.preferences?.findIndex(
        (object) => object.user === action.payload
      );
      let currentUserPreferences =
        typeof state.preferences !== "undefined" &&
        typeof state.preferences[userIndex] !== "undefined"
          ? { ...state.preferences[userIndex] }
          : {
              user: action.payload,
              dashboards: undefined,
              chats: undefined,
              viewmode: {
                users: ViewType.GRID,
                contacts: ViewType.GRID,
                customers: ViewType.GRID,
                teams: ViewType.GRID,
                whatsapp: ViewType.GRID,
                agidesk: ViewType.GRID,
                botmaker: ViewType.GRID,
                vtex: ViewType.GRID,
                officehours: ViewType.GRID,
                holidays: ViewType.GRID,
                offlinemessages: ViewType.GRID,
                surveys: ViewType.GRID,
                campaigns: ViewType.GRID,
                contacttags: ViewType.GRID,
                chattags: ViewType.GRID,
                commenttemplates: ViewType.GRID,
              },
            };

      if (
        typeof state.preferences !== "undefined" &&
        typeof state.preferences[userIndex] !== "undefined" &&
        typeof state.preferences[userIndex].viewmode === "undefined"
      ) {
        currentUserPreferences = {
          ...state.preferences[userIndex],
          viewmode: {
            users: ViewType.GRID,
            contacts: ViewType.GRID,
            customers: ViewType.GRID,
            teams: ViewType.GRID,
            whatsapp: ViewType.GRID,
            agidesk: ViewType.GRID,
            botmaker: ViewType.GRID,
            vtex: ViewType.GRID,
            officehours: ViewType.GRID,
            holidays: ViewType.GRID,
            offlinemessages: ViewType.GRID,
            surveys: ViewType.GRID,
            campaigns: ViewType.GRID,
            contacttags: ViewType.GRID,
            chattags: ViewType.GRID,
            commenttemplates: ViewType.GRID,
          },
        };
      }
      const newPreferences = {
        ...currentUserPreferences,
      };
      setUserPreferences(state, action.payload, newPreferences);
    },
    updateUserViewPreferences(
      state,
      action: PayloadAction<{
        user: string;
        location: keyof IViewMode;
        mode: ViewType;
      }>
    ) {
      if (typeof action.payload.user !== "undefined") {
        const currentPreferences = getUserPreferences(
          state,
          action.payload.user
        );
        const _viewmode = {
          ...currentPreferences?.viewmode,
          [action.payload.location]: action.payload.mode,
        };
        const newPreferences = {
          ...currentPreferences,
          viewmode: _viewmode,
        };
        setUserPreferences(state, action.payload.user, newPreferences);
        state.isSuccess = true;
      }
    },
    storeDashboardFilterOptions(
      state,
      action: PayloadAction<IStorableFilters>
    ) {
      prepareUserDashboardPreferences(action, state, "options");
    },
    storeDashboardFilterSelected(
      state,
      action: PayloadAction<IStorableFilters>
    ) {
      prepareUserDashboardPreferences(action, state, "selected");
    },
    updateDraft(state, action: PayloadAction<IChatPreferences>) {
      handleChatUserPreferences(state, action);
    },
    storeUserChatPreferences(state, action: PayloadAction<IChatPreferences>) {
      // handleChatUserPreferences(state, action);
    },
  },
});

export default preferencesSlice.reducer;
export const {
  storeDashboardFilterOptions,
  storeDashboardFilterSelected,
  storeUserChatPreferences,
  updateDraft,
  loadUserPreferences,
  updateUserViewPreferences,
} = preferencesSlice.actions;
